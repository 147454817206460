import { stringify } from 'qs'

function prepareUrlForQuery(resource, range, filters, sort, merge) {
  const { page, limit } = range || {}
  let query = {
    ...merge,
  }
  if (filters) {
    // eslint-disable-next-line
    for (const [key, value] of Object.entries(filters)) {
      query[`filters[${key}]`] = value
    }
  }
  if (page) {
    query = { ...query, 'range[page]': page }
  }
  if (limit) {
    query = { ...query, 'range[limit]': limit }
  }
  if (sort) {
    const { field, direction } = sort
    const parts = field.split('.')
    if (parts.length > 0) {
      const sortKey = parts.reduce((key, part) => `${key}[${part}]`, 'sort')
      query = {
        ...query,
        [sortKey]: direction,
      }
    }
  }

  const stringifiedQuery = stringify(query, { arrayFormat: 'brackets' })
  return [resource, stringifiedQuery].filter((el) => el).join('?')
}

export default {
  prepareUrlForQuery,
}
