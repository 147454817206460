import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  previewGridContainer: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '10px',
  },
  previewGridImage: {
    width: '100%',
  },
  imageContainer: {
    height: '50px',
  },
}))
