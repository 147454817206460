import React from 'react'

// components
import {
  Button,
  Typography,
} from '@material-ui/core'

import dateFnsFormat from 'date-fns/format'

export default function generate({
  t,
  classes,
  onSee,
  onSeeVehicles,
}) {
  return [
    {
      name: 'vehicleTypes',
      label: t('common.model'),
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => (val.map((el) => el.title).join(', ')),
      },
    },
    {
      name: 'controlType.name',
      label: t('control-points-list.control-type'),
    },
    {
      name: 'createdAt',
      label: t('control-points-list.creation-date'),
      options: {
        sort: true,
        customBodyRender: (val, tableMeta) => (
          <Typography variant="string">
            {dateFnsFormat(
              Date.parse(val),
              'dd/MM/yyyy H:mm:ss',
            )}
          </Typography>
        ),
      },
    },
    {
      name: 'updatedAt',
      label: t('control-points-list.update-date'),
      options: {
        sort: true,
        customBodyRender: (val, tableMeta) => (
          <Typography variant="string">
            {dateFnsFormat(
              Date.parse(val),
              'dd/MM/yyyy H:mm:ss',
            )}
          </Typography>
        ),
      },
    },
    {
      name: 'seeList',
      label: t('control-points-list.control-points'),
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => (
          <Button
            className={classes.linkButton}
            variant="text"
            onClick={() => onSee(tableMeta.rowIndex)}
            color="primary"
          >
            {t('common.see')}
          </Button>
        ),
      },
    },
    {
      name: 'vehiclesCount',
      label: t('control-points-list.vehicles'),
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => (
          <Button
            className={classes.linkButton}
            variant="text"
            onClick={() => onSeeVehicles(tableMeta.rowIndex)}
            color="primary"
          >
            {t('control-points-list.numberVehicles', { vehiclesCount: val })}
          </Button>
        ),
      },
    },
  ].filter((column) => !!column) // Filter nulls
}
