import { useState } from 'react'

const FREE_SOLO_INPUT_ID = -1

export default function useAutocompleteFreesolo() {

    const [value, setValue] = useState(null)
    const [valueFreeSolo, setValueFreeSolo] = useState('')

    const onChange = (data) => {
        if (typeof data === 'string') {
            setValue({
                id: FREE_SOLO_INPUT_ID,
                title: data
            })
        } else {
            setValue(data)
        }
    }

    const onInputChange = setValueFreeSolo

    const toObject = () => {
        let valueObj = null
        if (value && value.title === valueFreeSolo) {
            valueObj = {
                id: value.id,
                title: value.title
            }
        } else {
            valueObj = {
                id: FREE_SOLO_INPUT_ID,
                title: valueFreeSolo
            }
        }
        return valueObj
    }

    const set = (val) => {
        const newTitle = val && val.title
        const newId = val && val.id
        if (newTitle || newId) {
            if (value) {
                if (value.id !== newId || value.title !== newTitle) {
                    setValue((current) => {
                        return {
                            id: newId || current.id,
                            title: newTitle || current.title
                        }
                    })
                }
            } else {
                setValue({
                    id: val.id,
                    title: val.title
                })
            }

            if (valueFreeSolo !== newTitle) {
                let newValueFreeSolo = (newTitle || '').trim()
                setValueFreeSolo(newValueFreeSolo)
            }
        } else {
            clear()
        }
    }

    const clear = () => {
        setValue(null)
        setValueFreeSolo('')
    }

    return {
        value,
        set,
        onChange,
        onInputChange,
        clear,
        toObject
    }
}