import LRU from 'lru-cache'
import Utils from '../utils/common'

const { prepareUrlForQuery } = Utils

const cacheOptions = {
  max: 100,
  maxAge: 1000 * 60, // 1 minute in miliseconds
}

const cache = new LRU(cacheOptions)

function getList(resource, params, httpClient) {
  const url = prepareUrlForQuery(resource, params.range, params.filters, params.sort)
  const cachedData = cache.get(url)
  if (cachedData) {
    return Promise.resolve(cachedData)
  }
  return httpClient({ url, method: 'GET' }).then((response) => {
    cache.set(url, response)
    return response
  })
}

function post(name, address, httpClient) {
  cache.reset()
  const body = JSON.stringify({
    name, address,
  })

  return httpClient({
    url: '/clients',
    method: 'POST',
    data: body,
  })
}

function put(clientId, name, address, httpClient) {
  cache.reset()
  const body = JSON.stringify({
    name, address
  })

  return httpClient({
    url: `/clients/${clientId}`,
    method: 'PUT',
    data: body,
  })
}

export default {
  getList,
  post,
  put,
}
