import React, { useState, useEffect, useMemo, useCallback } from 'react'

// components
import { useTranslation } from 'react-i18next'

import {
  Grid,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import Loading from '../../components/Loading'
import PageTitle from '../../components/PageTitle'
import { useNotificationsProvider } from '../../context/NotificationsContext'
import { useConfirmationDialogProvider } from '../../context/ConfirmationDialogContext'
import { useDataApi } from '../../context/DataApiContext'
import { useQueryState, toQueryString } from '../../utils/query'
import useStyles from './styles'

// eslint-disable-next-line max-len
import AutoCompleteInput from '../../components/AutoCompleteInput'
import CreateClientDialog from '../../components/Clients/CreateClientDialog'
// eslint-disable-next-line max-len
import ModifyClientDialog from '../../components/Clients/ModifyClientDialog'
import Table from '../../components/Table'
import generateColumns from './columns'

import { ReactComponent as TrashIcon } from '../../assets/icons-Delete.svg'
import { ReactComponent as EditIcon } from '../../assets/Icons_Edit.svg'

export default function ClientsList({ history }) {
  const { t } = useTranslation()

  const theme = useTheme()
  const fullWidth = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles()
  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()
  const { showConfirmationDialog } = useConfirmationDialogProvider()

  const [openCreateClientDialog, setOpenCreateClientDialog] = useState(false)
  const [openModifyClientDialog, setOpenModifyClientDialog] = useState(false)
  const [clientToModify, setClientToModify] = useState(false)
  const [selection, setSelection] = useState({ rowsSelected: [] })

  const resource = 'clients'

  const [address, setAddress] = useQueryState({ paramName: 'address', history })
  const [client, setClient] = useQueryState({ paramName: 'client', history })

  const computedFilters = useMemo(() => {
    const filtersObj = {
      addressId: address ? address.id : null,
      clientId: client ? client.id : null,
    }
    // remove nulls
    Object.keys(filtersObj).forEach(
      (key) => filtersObj[key] === null && delete filtersObj[key],
    )
    return filtersObj
  }, [address, client])

  const [loading, setLoading] = useState(true)
  const [tableSort, setTableSort] = useQueryState({ paramName: 'sort', history })
  const [tablePage, setTablePage] = useQueryState({
    paramName: 'page', defaultValue: 0, history,
  })
  const [tableState, setTableState] = useState({
    page: tablePage, sort: tableSort, filters: computedFilters,
  })
  const [tableData, setTableData] = useState(null)

  useEffect(() => {
    dataProvider.getList(
      resource,
      {
        range: { page: tableState.page + 1 },
        filters: tableState.filters,
        sort: tableState.sort,
      },
    ).then((response) => {
      setTableData(response.data)
      setLoading(false)
    }).catch(() => showNotification('error', t('errors.api-error')))

    setTablePage(tableState.page)
    setTableSort(tableState.sort)
    // eslint-disable-next-line
  }, [tableState])

  const updateFilters = () => {
    setTableState({ ...tableState, page: 0, filters: computedFilters })
  }

  const clearFilters = () => {
    setClient(null)
    setAddress(null)
    setTableState({ ...tableState, page: 0, filters: null })
  }

  const onSeeVehicles = (rowIndex) => {
    const client = tableData.items[rowIndex]
    if (client) {
      const clientFilter = {
        id: client.id,
        title: client.name
      }
      const queryParams = {
        client: clientFilter,
      }
      const queryString = toQueryString(queryParams)
      history.push(`/vehicles?${queryString}`)
    }
  }

  const refreshData = useCallback((keepPage = false) => {
    if (keepPage) {
      setTableState(state => ({ ...state }))
    } else {
      setTableState({ page: 0, sort: null, filters: null })
    }
  }, [setTableState])

  const performDelete = useCallback((rowIndexes) => {
    if (!rowIndexes || rowIndexes.length === 0) {
      return
    }
    let dialogTitle = t('clients-list.confirm-client-deletion.multiple')
    const idsToDelete = tableData.items
      .filter((item, index) => rowIndexes.indexOf(index) !== -1)
      .map((item) => item.id)

    if (idsToDelete.length === 1) {
      const { name } = tableData.items[rowIndexes[0]]
      dialogTitle = t('clients-list.confirm-client-deletion.single', { name })
    }
    showConfirmationDialog(
      dialogTitle,
      () => {
        dataProvider
          .deleteBulk('/clients/bulk', idsToDelete)
          .then(
            () => {
              // Sync row selection by removing deleted elements
              // eslint-disable-next-line max-len
              const filteredSelection = selection.rowsSelected.filter((index) => rowIndexes.indexOf(index) === -1)
              setSelection({ rowsSelected: filteredSelection })

              showNotification('success', t('common.success'))
              refreshData(true)
            },
          ).catch(
            () => showNotification('error', t('errors.api-error')),
          )
      },
    )
    // eslint-disable-next-line max-len
  }, [dataProvider, refreshData, selection.rowsSelected, showConfirmationDialog, showNotification, t, tableData])

  const onModify = () => {
    if (selection && selection.rowsSelected && selection.rowsSelected.length === 1) {
      const clientIndex = selection.rowsSelected[0]
      const client = tableData.items[clientIndex]
      setClientToModify(client)
      setOpenModifyClientDialog(true)
    }
  }

  const onDeleteSelection = () => {
    if (selection && selection.rowsSelected) {
      performDelete(selection.rowsSelected)
    }
  }

  const tableColumns = generateColumns({
    t,
    classes,
    onSeeVehicles
  })

  if (loading) {
    return <Loading />
  }
  return (
    <>
      <ModifyClientDialog
        open={openModifyClientDialog}
        onClose={() => {
          setOpenModifyClientDialog(false)
          setClientToModify(false)
        }}
        clientToModify={clientToModify}
        onModified={() => {
          refreshData(true)
          setOpenModifyClientDialog(false)
        }}
      />
      <CreateClientDialog
        open={openCreateClientDialog}
        onClose={() => setOpenCreateClientDialog(false)}
        onCreated={() => {
          refreshData(true)
          setOpenCreateClientDialog(false)
        }}
      />
      <Grid container direction="column">
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={12} sm={4}>
            <PageTitle title={t('clients-list.title')} />
          </Grid>
          <Grid item container sm={8} justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              fullWidth={fullWidth}
              onClick={() => setOpenCreateClientDialog(true)}
            >
              {t('clients-list.add-client')}
            </Button>
          </Grid>
        </Grid>
        <Grid container item className={classes.filtersContainer}>
          <Grid item>
            <Typography variant="h6" className={classes.filterContainerTitleText}>
              {t('common.filterZone')}
            </Typography>
          </Grid>
          <Grid item container alignItems="center" spacing={1}>
            <Grid item xs={12} md={3}>
              <AutoCompleteInput
                onChange={setClient}
                label={t('common.client')}
                resource="clients/autocomplete"
                value={client}
                canDelete
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <AutoCompleteInput
                onChange={setAddress}
                label={t('clients-list.address-table-header')}
                resource="clients/address-autocomplete"
                value={address}
                canDelete
              />
            </Grid>
          </Grid>
          <Grid item container alignItems="center" justifyContent="space-between" spacing={1}>
            <Grid item xs={12} md={6}>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2} justifyContent="flex-end" className={classes.actionsContainer}>
                <Grid item xs={12} md={4} xl={2}>
                  <Button
                    color="primary"
                    variant="text"
                    onClick={clearFilters}
                    fullWidth
                  >
                    {t('common.clean')}
                  </Button>
                </Grid>
                <Grid item xs={12} md={4} xl={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    onClick={updateFilters}
                  >
                    {t('common.search')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item>
            <Button
              disabled={!(selection && selection.rowsSelected && selection.rowsSelected.length === 1)}
              variant="contained"
              fullWidth={fullWidth}
              className={[classes.selectionButton].join(' ')}
              onClick={() => onModify()}
            >
              <EditIcon id="icon" />
              <span id="text">{t('common.modify')}</span>
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={!(selection && selection.rowsSelected && selection.rowsSelected.length > 0)}
              variant="contained"
              fullWidth={fullWidth}
              className={[classes.selectionButton].join(' ')}
              onClick={() => onDeleteSelection()}
            >
              <TrashIcon id="icon" />
              <span id="text">
                {selection && selection.rowsSelected && selection.rowsSelected.length > 1
                  ? t('clients-list.delete-client.multiple')
                  : t('clients-list.delete-client.single')}
              </span>
            </Button>
          </Grid>
        </Grid>
        <Grid item className={classes.tableContainer} xs={12}>
          <Table
            id={resource}
            columns={tableColumns}
            data={tableData}
            page={tableState.page}
            sort={tableState.sort}
            onChangePage={(currentPage) => {
              setTableState({ ...tableState, page: currentPage })
            }}
            onColumnSortChange={(changedColumn, direction) => {
              const newSort = {
                field: changedColumn,
                direction: direction.toUpperCase(),
              }
              setTableState({ ...tableState, sort: newSort })
            }}
            selectableRows="multiple"
            rowsSelected={selection.rowsSelected}
            onRowSelectionChange={(rowsSelected, allRows) => {
              setSelection({ rowsSelected: allRows.map((row) => row.dataIndex) })
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}
