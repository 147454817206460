const CONFORMITY = {
  INVALID: 0,
  VALID: 1,
  isValid(value) {
    let val = value
    if (typeof value !== 'number') {
      if (value === '0' || value === '1') {
        val = Number(value)
      }
    }
    return val === this.INVALID || val === this.VALID
  },
}

export default { CONFORMITY }
