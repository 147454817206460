import React, { useState, useEffect } from 'react'
import {
  Grid,
  Typography,
} from '@material-ui/core'

// styles
import { useTranslation } from 'react-i18next'
import useStyles from './styles'

// components
import Widget from '../../components/Widget'
import PageTitle from '../../components/PageTitle'
import { useDataApi } from '../../context/DataApiContext'
import Loading from '../../components/Loading'
import { useNotificationsProvider } from '../../context/NotificationsContext'

export default function Dashboard() {
  const classes = useStyles()
  const { t } = useTranslation()
  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()

  const [failedLoad, setFailedLoad] = useState(false)
  const [counts, setCounts] = useState(null)

  useEffect(() => {
    dataProvider.get('/dashboard', {})
      .then((response) => setCounts(response.data))
      .catch(() => setFailedLoad(true))
  }, [dataProvider])

  useEffect(() => {
    if (!failedLoad) {
      return
    }

    showNotification('error', t('errors.api-error'))
    setFailedLoad(false)
  }, [showNotification, failedLoad, t])

  // still loading
  if (!counts) {
    return <Loading />
  }
  // local
  return (
    <>
      <Grid container justifyContent="flex-start" direction='column'>
        <Grid item xs={12} sm={6}>
          <PageTitle title={t('dashboard.title')} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography>{t('dashboard.counts-title')}</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={4} justifyContent="flex-start" className={classes.cardsContainer} wrap="wrap">
        {counts && counts.counts.map((count) => {
          return (<Grid item lg={3} md={4} sm={6} xs={12} key={count.label}>
            <Widget
              title={count.label}
              upperTitle
              bodyClass={classes.fullHeightBody}
              className={classes.card}
              disableWidgetMenu
            >
              <div className={classes.visitsNumberContainer}>
                <Typography variant="h3">
                  {count.count}
                </Typography>
              </div>
            </Widget>
          </Grid>)
        })}
      </Grid>
    </>
  )
}
