import React, { useState, useEffect, useMemo, useCallback } from 'react'

// components
import { useTranslation } from 'react-i18next'
import {
  Grid,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import Loading from '../../components/Loading'
import Table from '../../components/Table'
import PageTitle from '../../components/PageTitle'
import useStyles from './styles'
import { useDataApi } from '../../context/DataApiContext'
import { useNotificationsProvider } from '../../context/NotificationsContext'
import { useConfirmationDialogProvider } from '../../context/ConfirmationDialogContext'
import { useQueryState } from '../../utils/query'
import AutoCompleteInput from '../../components/AutoCompleteInput'
import CreateVehicleDialog from '../../components/Vehicle/components/CreateVehicleDialog'
import ModifyVehicleDialog from '../../components/Vehicle/components/ModifyVehicleDialog'
import generateColumns from './columns'

import { ReactComponent as TrashIcon } from '../../assets/icons-Delete.svg'
import { ReactComponent as EditIcon } from '../../assets/Icons_Edit.svg'

export default function VehicleList({ history }) {
  const { t } = useTranslation()
  const theme = useTheme()
  const fullWidth = useMediaQuery(theme.breakpoints.down('xs'))

  const classes = useStyles()
  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()
  const { showConfirmationDialog } = useConfirmationDialogProvider()

  const [openCreateVehicleDialog, setOpenCreateVehicleDialog] = useState(false)
  const [openModifyVehicleDialog, setOpenModifyVehicleDialog] = useState(false)
  const [selection, setSelection] = useState({ rowsSelected: [] })

  const resource = 'vehicle'

  const [vehicleType, setVehicleType] = useQueryState({ paramName: 'vehicleTypes', history, defaultValue: [] })
  const [nSerie, setNSerie] = useQueryState({ paramName: 'nSerie', history })
  const [nIntern, setNIntern] = useQueryState({ paramName: 'nIntern', history })
  const [plate, setPlate] = useQueryState({ paramName: 'plate', history })
  const [client, setClient] = useQueryState({ paramName: 'client', history })

  const computedFilters = useMemo(() => {
    const filtersObj = {
      vehicleTypeId: Array.isArray(vehicleType) && vehicleType.length > 0 ? vehicleType.map(el => el.id) : null,
      nSerie: nSerie ? nSerie.title : null,
      nIntern: nIntern ? nIntern.title : null,
      plate: plate ? plate.title : null,
      clientId: client ? client.id : null,
    }
    // remove nulls
    Object.keys(filtersObj).forEach(
      (key) => filtersObj[key] === null && delete filtersObj[key],
    )
    return filtersObj
  }, [vehicleType, nSerie, nIntern, plate, client])

  const [loading, setLoading] = useState(true)
  const [tableSort, setTableSort] = useQueryState({ paramName: 'sort', history })
  const [tablePage, setTablePage] = useQueryState({
    paramName: 'page', defaultValue: 0, history,
  })
  const [tableState, setTableState] = useState({
    page: tablePage, sort: tableSort, filters: computedFilters,
  })
  const [tableData, setTableData] = useState(null)

  useEffect(() => {
    dataProvider.getList(
      resource,
      {
        range: { page: tableState.page + 1 },
        filters: tableState.filters,
        sort: tableState.sort,
      },
    ).then((response) => {
      setTableData(response.data)
      setLoading(false)
    }).catch(() => showNotification('error', t('errors.api-error')))

    setTablePage(tableState.page)
    setTableSort(tableState.sort)
    // eslint-disable-next-line
  }, [tableState])

  const updateFilters = () => {
    setTableState({ ...tableState, page: 0, filters: computedFilters })
  }

  const clearFilters = () => {
    setVehicleType([])
    setNSerie(null)
    setNIntern(null)
    setPlate(plate)
    setClient(null)
    setTableState({ ...tableState, page: 0, filters: null })
  }

  const [vehicleToModify, setVehicleToModify] = useState(null)

  const refreshData = useCallback((keepPage = false) => {
    if (keepPage) {
      setTableState(state => ({ ...state }))
    } else {
      setTableState({
        page: 0, sort: null, filters: null,
      })
    }
  }, [setTableState])

  const performDelete = useCallback((rowIndexes) => {
    if (!rowIndexes || rowIndexes.length === 0) {
      return
    }
    let dialogTitle = t('vehicle-list.confirm-vehicle-deletion.multiple')
    const idsToDelete = tableData.items
      .filter((item, index) => rowIndexes.indexOf(index) !== -1)
      .map((item) => item.id)

    if (idsToDelete.length === 1) {
      const { nSerie } = tableData.items[rowIndexes[0]]
      dialogTitle = t('vehicle-list.confirm-vehicle-deletion.single', { nSerie })
    }
    showConfirmationDialog(
      dialogTitle,
      () => {
        dataProvider
          .deleteBulk('/vehicle/bulk', idsToDelete)
          .then(
            () => {
              // Sync row selection by removing deleted elements
              // eslint-disable-next-line max-len
              const filteredSelection = selection.rowsSelected.filter((index) => rowIndexes.indexOf(index) === -1)
              setSelection({ rowsSelected: filteredSelection })

              showNotification('success', t('common.success'))
              refreshData(true)
            },
          ).catch(
            () => showNotification('error', t('errors.api-error')),
          )
      },
    )
    // eslint-disable-next-line max-len
  }, [dataProvider, refreshData, selection.rowsSelected, showConfirmationDialog, showNotification, t, tableData])

  const onModify = () => {
    if (selection && selection.rowsSelected && selection.rowsSelected.length === 1) {
      const vehicleIndex = selection.rowsSelected[0]
      setVehicleToModify(tableData.items[vehicleIndex])
      setOpenModifyVehicleDialog(true)
    }
  }

  const onDeleteSelection = () => {
    if (selection && selection.rowsSelected) {
      performDelete(selection.rowsSelected)
    }
  }

  const tableColumns = generateColumns({
    t,
    classes,
  })

  if (loading) {
    return <Loading />
  }
  return (
    <>
      <CreateVehicleDialog
        open={openCreateVehicleDialog}
        onClose={() => setOpenCreateVehicleDialog(false)}
        onCreated={() => {
          refreshData(true)
          setOpenCreateVehicleDialog(false)
        }}
      />
      <ModifyVehicleDialog
        open={openModifyVehicleDialog}
        onClose={() => {
          setOpenModifyVehicleDialog(false)
          setVehicleToModify(null)
        }}
        onCreated={() => {
          refreshData(true)
          setOpenModifyVehicleDialog(false)
        }}
        vehicleToModify={vehicleToModify}
      />
      <Grid container direction="column">
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={12} sm={4}>
            <PageTitle title={t('vehicle-list.title')} />
          </Grid>
          <Grid item container sm={8} justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              fullWidth={fullWidth}
              onClick={() => setOpenCreateVehicleDialog(true)}
            >
              {t('create-vehicle-dialog.title')}
            </Button>
          </Grid>
        </Grid>
        <Grid container item className={classes.filtersContainer}>
          <Grid item>
            <Typography variant="h6" className={classes.filterContainerTitleText}>
              {t('common.filterZone')}
            </Typography>
          </Grid>
          <Grid item container alignItems="center" justifyContent="space-between" spacing={1}>
            <Grid item xs={12} md={3}>
              <AutoCompleteInput
                onChange={setVehicleType}
                label={t('common.model')}
                resource="vehicle-type/autocomplete"
                value={vehicleType}
                multiple
                canDelete
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <AutoCompleteInput
                onChange={setNSerie}
                label={t('common.nSerie')}
                resource="vehicle/serie-number-autocomplete"
                value={nSerie}
                canDelete
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <AutoCompleteInput
                onChange={setNIntern}
                label={t('controls-list.n-intern-filter')}
                resource="vehicle/intern-number-autocomplete"
                value={nIntern}
                canDelete
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <AutoCompleteInput
                onChange={setPlate}
                label={t('controls-list.plate-filter')}
                resource="vehicle/license-plate-autocomplete"
                value={plate}
                canDelete
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <AutoCompleteInput
                onChange={setClient}
                label={t('common.client')}
                resource="clients/autocomplete"
                value={client}
                canDelete
              />
            </Grid>
          </Grid>
          <Grid item container alignItems="center" justifyContent="space-between" spacing={1}>
            <Grid item xs={12} md={6}>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2} justifyContent="flex-end" className={classes.actionsContainer}>
                <Grid item xs={12} md={4} xl={2}>
                  <Button
                    color="primary"
                    variant="text"
                    onClick={clearFilters}
                    fullWidth
                  >
                    {t('common.clean')}
                  </Button>
                </Grid>
                <Grid item xs={12} md={4} xl={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    onClick={updateFilters}
                  >
                    {t('common.search')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item>
            <Button
              disabled={!(selection && selection.rowsSelected && selection.rowsSelected.length === 1)}
              variant="contained"
              fullWidth={fullWidth}
              className={[classes.selectionButton].join(' ')}
              onClick={() => onModify()}
            >
              <EditIcon id="icon" />
              <span id="text">{t('common.modify')}</span>
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={!(selection && selection.rowsSelected && selection.rowsSelected.length > 0)}
              variant="contained"
              fullWidth={fullWidth}
              className={[classes.selectionButton].join(' ')}
              onClick={() => onDeleteSelection()}
            >
              <TrashIcon id="icon" />
              <span id="text">
                {selection && selection.rowsSelected && selection.rowsSelected.length > 1
                  ? t('vehicle-list.delete-vehicle.multiple')
                  : t('vehicle-list.delete-vehicle.single')}
              </span>
            </Button>
          </Grid>
        </Grid>
        <Grid item className={classes.tableContainer} xs={12}>
          <Table
            id={resource}
            columns={tableColumns}
            data={tableData}
            page={tableState.page}
            sort={tableState.sort}
            onChangePage={(currentPage) => {
              setTableState({ ...tableState, page: currentPage })
            }}
            onColumnSortChange={(changedColumn, direction) => {
              const newSort = {
                field: changedColumn,
                direction: direction.toUpperCase(),
              }
              setTableState({ ...tableState, sort: newSort })
            }}
            selectableRows="multiple"
            rowsSelected={selection.rowsSelected}
            onRowSelectionChange={(rowsSelected, allRows) => {
              setSelection({ rowsSelected: allRows.map((row) => row.dataIndex) })
            }}
          />
        </Grid>

      </Grid>
    </>
  )
}
