import { useState, useEffect, useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export function mutateQuery(query, paramName, paramValue) {
  if (query.has(paramName) && query.get(paramName) === paramValue) {
    return
  }
  query.set(paramName, paramValue)
}

export function useQueryState({
  paramName,
  history,
  fromQuery,
  toQuery,
  defaultValue = null,
}) {
  const fromQueryFn = useCallback((value) => {
    if (typeof fromQuery !== 'function') {
      return (value ? JSON.parse(value) : defaultValue)
    }
    return fromQuery(value)
  }, [fromQuery, defaultValue])
  const toQueryFn = useCallback((value) => {
    if (typeof toQuery !== 'function') {
      return JSON.stringify(value)
    }
    return toQuery(value)
  }, [toQuery])

  const query = useQuery()
  const currentQueryValue = query.get(paramName)
  const [state, setState] = useState(fromQueryFn(currentQueryValue))
  useEffect(() => {
    if (!state) {
      if (query.has(paramName)) {
        query.delete(paramName)
        history.replace({ search: ['?', query.toString()].join('') })
        return
      }
      return
    }
    const paramValue = toQueryFn(state)
    mutateQuery(query, paramName, paramValue)
    history.replace({ search: ['?', query.toString()].join('') })
  }, [history, query, state, paramName, toQueryFn])
  return [state, setState]
}

export function useQuery() {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

export function toQueryString(params) {
  const searchParams = new URLSearchParams()
  for (const name in params) {
    const value = JSON.stringify(params[name])
    searchParams.set(name, value)
  }
  return searchParams.toString()
}