import React, { useState } from 'react'
import {
  Grid,
  CircularProgress,
  Button,
  TextField,
  InputAdornment,
  IconButton,
} from '@material-ui/core'
import {
  Visibility,
  VisibilityOff,
} from '@material-ui/icons'
import { withRouter } from 'react-router-dom'

// styles
import { useTranslation } from 'react-i18next'
import useStyles from './styles'

// logo
import logo from '../../assets/logo.png'

// context
import { useUserDispatch, loginUser } from '../../context/UserContext'
import validatores from '../../utils/validators'

function Login(props) {
  const classes = useStyles()

  // global
  const userDispatch = useUserDispatch()
  const { t } = useTranslation()

  // local
  const [isLoading, setIsLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState(null)
  const [loginValue, setLoginValue] = useState('')
  const [passwordValue, setPasswordValue] = useState('')

  return (
    <Grid container justifyContent="center" className={classes.container}>
      <Grid
        item
        container
        xs={11}
        sm={6}
        md={4}
        lg={3}
        xl={2}
        className={classes.formContainer}
        spacing={5}
      >
        <Grid item container xs={10}>
          <Grid item className={classes.logoContainer}>
            <img src={logo} alt={logo} className={classes.logo} />
          </Grid>
          <Grid item className={classes.form}>
            <form onSubmit={() => loginUser(
              userDispatch,
              loginValue,
              passwordValue,
              props.history,
              setIsLoading,
              setError,
            )}
            >
              <TextField
                id="email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={loginValue}
                onChange={(e) => setLoginValue(e.target.value)}
                margin="normal"
                label={t('login-view.email')}
                type="email"
                fullWidth
                error={error !== null}
                required
              />
              <TextField
                id="password"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={passwordValue}
                onChange={(e) => setPasswordValue(e.target.value)}
                margin="normal"
                label={t('login-view.password')}
                helperText={t(error)}
                error={error !== null}
                type={showPassword ? 'text' : 'password'}
                fullWidth
                required
              />
              <Grid item container className={classes.formButtons}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    type="submit"
                    disabled={
                    !validatores.isValidEmail(loginValue) || passwordValue.length === 0
                  }
                    onClick={() => loginUser(
                      userDispatch,
                      loginValue,
                      passwordValue,
                      props.history,
                      setIsLoading,
                      setError,
                    )}
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                  >
                    {t('login-view.login')}
                  </Button>
                )}
                <Button
                  color="primary"
                  size="large"
                  className={classes.forgetButton}
                  href="/recover-password"
                  fullWidth
                >
                  {t('login-view.forgot-password')}
                </Button>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withRouter(Login)
