import React, { useState, useEffect, useMemo } from 'react'

// components
import { useTranslation } from 'react-i18next'
import {
  Grid,
  Typography,
  Button,
} from '@material-ui/core'
import Loading from '../../components/Loading'
import DatePicker from '../../components/DatePicker'

import { useDataApi } from '../../context/DataApiContext'
import { useNotificationsProvider } from '../../context/NotificationsContext'
import { useQueryState } from '../../utils/query'

import Table from '../../components/Table'
import PageTitle from '../../components/PageTitle'
import useStyles from './styles'
import AutoCompleteInput from '../../components/AutoCompleteInput'
import generateColumns from './columns'

export default function ControlsList({ history }) {
  const { t } = useTranslation()
  const classes = useStyles()

  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [vehicleType, setVehicleType] = useQueryState({ paramName: 'vehicleType', history })
  const [nSerie, setNSerie] = useQueryState({ paramName: 'nSerie', history })
  const [controlType, setControlType] = useQueryState({ paramName: 'controlType', history })
  const [client, setClient] = useQueryState({ paramName: 'client', history })

  const resource = 'control'

  const computedFilters = useMemo(() => {
    const filtersObj = {
      startDate: startDate ? startDate.toISOString() : null,
      endDate: endDate ? endDate.toISOString() : null,
      vehicleTypeId: vehicleType ? vehicleType.id : null,
      nSerie: nSerie ? nSerie.title : null,
      controlTypeId: controlType ? controlType.id : null,
      clientId: client ? client.id : null,
    }
    // remove nulls
    Object.keys(filtersObj).forEach(
      (key) => filtersObj[key] === null && delete filtersObj[key],
    )
    return filtersObj
  }, [startDate, endDate, vehicleType, nSerie, controlType, client])

  const [loading, setLoading] = useState(true)
  const [tableSort, setTableSort] = useQueryState({ paramName: 'sort', history })
  const [tablePage, setTablePage] = useQueryState({
    paramName: 'page', defaultValue: 0, history,
  })
  const [tableState, setTableState] = useState({
    page: tablePage, sort: tableSort, filters: computedFilters,
  })
  const [tableData, setTableData] = useState(null)

  useEffect(() => {
    dataProvider.getList(
      resource,
      {
        range: { page: tableState.page + 1 },
        filters: tableState.filters,
        sort: tableState.sort,
      },
    ).then((response) => {
      setTableData(response.data)
      setLoading(false)
    }).catch(() => showNotification('error', t('errors.api-error')))
    
    setTablePage(tableState.page)
    setTableSort(tableState.sort)
    // eslint-disable-next-line
  }, [tableState])

  const updateFilters = () => {
    setTableState({ ...tableState, page: 0, filters: computedFilters })
  }

  const clearFilters = () => {
    setStartDate(null)
    setEndDate(null)
    setVehicleType(null)
    setNSerie(null)
    setControlType(null)
    setClient(null)
    setTableState({ ...tableState, page: 0, filters: null })
  }

  const onControlClick = (idx) => {
    const control = tableData.items[idx]
    if (control) {
      history.push(`/controls/${control.id}`)
    }
  }

  const tableColumns = generateColumns({
    t,
    classes,
    tableData,
    onControlClick,
  })

  if (loading) {
    return <Loading />
  }
  return (
    <Grid container direction="column">
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={6}>
          <PageTitle title={t('controls-list.title')} />
        </Grid>
      </Grid>
      <Grid container item className={classes.filtersContainer}>
        <Grid item>
          <Typography variant="h6" className={classes.filterContainerTitleText}>
            {t('common.filterZone')}
          </Typography>
        </Grid>
        <Grid item container alignItems="center" justifyContent="space-between" spacing={1}>
          <Grid item xs={12} md={3}>
            <AutoCompleteInput
              onChange={setVehicleType}
              label={t('common.model')}
              resource="vehicle-type/autocomplete"
              value={vehicleType}
              canDelete
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <AutoCompleteInput
              onChange={setNSerie}
              label={t('common.nSerie')}
              resource="vehicle/serie-number-autocomplete"
              value={nSerie}
              canDelete
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <AutoCompleteInput
              onChange={setControlType}
              label={t('controls-list.control-type-filter')}
              resource="control-types/autocomplete"
              value={controlType}
              canDelete
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <AutoCompleteInput
              onChange={setClient}
              label={t('common.client')}
              resource="clients/autocomplete"
              value={client}
              canDelete
            />
          </Grid>
        </Grid>
        <Grid item container alignItems="center" justifyContent="space-between" spacing={1}>
          <Grid item xs={12} md={6}>
            <Grid container alignItems="flex-end" className={classes.alignItemsBaseline}>
              <Grid item xs={12} md={5}>
                <DatePicker
                  id="startDate"
                  value={startDate}
                  onChange={setStartDate}
                  label={t('common.startDate')}
                />
              </Grid>
              <Grid item md={1}>
                <Typography variant="subtitle1" align="center">
                  Au
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <DatePicker
                  id="endDate"
                  value={endDate}
                  onChange={setEndDate}
                  label={t('common.endDate')}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2} justifyContent="flex-end" className={classes.actionsContainer}>
              <Grid item xs={12} md={4} xl={2}>
                <Button
                  color="primary"
                  variant="text"
                  onClick={clearFilters}
                  fullWidth
                >
                  {t('common.clean')}
                </Button>
              </Grid>
              <Grid item xs={12} md={4} xl={2}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  onClick={updateFilters}
                >
                  {t('common.search')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.tableContainer} xs={12}>
        <Table
          id={resource}
          columns={tableColumns}
          data={tableData}
          page={tableState.page}
          sort={tableState.sort}
          onRowClick={(data, indexes) => onControlClick(indexes.rowIndex)}
          onChangePage={(currentPage) => {
            setTableState({ ...tableState, page: currentPage })
          }}
          onColumnSortChange={(changedColumn, direction) => {
            const newSort = {
              field: changedColumn,
              direction: direction.toUpperCase(),
            }
            setTableState({ ...tableState, sort: newSort })
          }}
        />
      </Grid>

    </Grid>
  )
}
