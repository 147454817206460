import React, { useState, useEffect } from 'react'
import {
  Drawer,
  IconButton,
  List,
} from '@material-ui/core'
import {
  Home as HomeIcon,
  ArrowBack as ArrowBackIcon,
} from '@material-ui/icons'

import { useTheme } from '@material-ui/styles'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'

// styles
import { useTranslation } from 'react-i18next'
import useStyles from './styles'
import ControlIcon from '../Control/Icon'
import VehicleIcon from '../Vehicle/Icon'
import ControlItemsListsIcon from '../ControlItemsList/Icon'
import UsersIcon from '../Users/Icon'
import VehicleTypeIcon from '../VehicleType/Icon'
import ClientsIcon from '../Clients/Icon'

// components
import SidebarLink from './components/SidebarLink/SidebarLink'

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from '../../context/LayoutContext'
import { useUserState, hasPermission } from '../../context/UserContext'
import { Permissions } from '../../entities/permissions'

function Sidebar({ location }) {
  const classes = useStyles()
  const theme = useTheme()

  // global
  const { isSidebarOpened } = useLayoutState()
  const layoutDispatch = useLayoutDispatch()
  const { t } = useTranslation()
  const { user } = useUserState()

  // local
  const [isPermanent, setPermanent] = useState(true)

  const structure = []
  if (hasPermission(Permissions.PAGE_DASHBOARD_ROOT, user)) {
    structure.push(
      {
        label: t('sidebar.dashboard'),
        icon: <HomeIcon />,
        link: '/dashboard',
      },
    )
  }
  if (hasPermission(Permissions.PAGE_VEHICLES_TYPE_ROOT, user)) {
    structure.push(
      {
        label: t('sidebar.vehicle-types'),
        icon: <VehicleTypeIcon />,
        link: '/vehicle-types',
      },
    )
  }
  if (hasPermission(Permissions.PAGE_CONTROL_ITEMS_LISTS_ROOT, user)) {
    structure.push(
      {
        label: t('sidebar.control-items-lists'),
        icon: <ControlItemsListsIcon />,
        link: '/control-items-lists',
      },
    )
  }
  if (hasPermission(Permissions.PAGE_CONTROLS_ROOT, user)) {
    structure.push(
      {
        label: t('sidebar.controls'),
        link: '/controls',
        icon: <ControlIcon />,
      },
    )
  }
  if (hasPermission(Permissions.PAGE_VEHICLES_ROOT, user)) {
    structure.push(
      {
        label: t('sidebar.vehicles'),
        link: '/vehicles',
        icon: <VehicleIcon />,
      },
    )
  }
  if (hasPermission(Permissions.PAGE_CLIENTS_ROOT, user)) {
    structure.push(
      {
        label: t('sidebar.clients'),
        link: '/clients',
        icon: <ClientsIcon />,
      },
    )
  }

  if (hasPermission(Permissions.PAGE_USERS_ROOT, user)) {
    structure.push(
      {
        label: t('sidebar.users'),
        icon: <UsersIcon />,
        link: '/users',
      },
    )
  }

  function handleWindowWidthChange() {
    const windowWidth = window.innerWidth
    const breakpointWidth = theme.breakpoints.values.md
    const isSmallScreen = windowWidth < breakpointWidth

    if (isSmallScreen && isPermanent) {
      setPermanent(false)
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowWidthChange)
    handleWindowWidthChange()
    return function cleanup() {
      window.removeEventListener('resize', handleWindowWidthChange)
    }
  })

  return (
    <Drawer
      variant={isPermanent ? 'permanent' : 'temporary'}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map((link) => (
          <SidebarLink
            key={link.label}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  )
}

export default withRouter(Sidebar)
