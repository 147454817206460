import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    '&:hover, &:focus': {
      backgroundColor: theme.palette.background.light,
    },
  },
  linkActive: {
    backgroundColor: theme.palette.background.light,
  },
  linkNested: {
    paddingLeft: 0,
    '&:hover, &:focus': {
      backgroundColor: '#FFFFFF',
    },
  },
  linkIcon: {
    marginLeft: theme.spacing(0.10),
    color: `${theme.palette.text.secondary}99`,
    transition: theme.transitions.create('color'),
    display: 'flex',
    justifyContent: 'left',
    minWidth: '35px',
  },
  linkIconActive: {
    color: theme.palette.secondary.main,
  },
  linkText: {
    padding: 0,
    color: 'black',
    transition: theme.transitions.create(['opacity', 'color']),
    fontSize: 14,
  },
  linkTextActive: {
    color: theme.palette.text.primary,
  },
  linkTextHidden: {
    opacity: 0,
  },
  nestedList: {
    paddingLeft: theme.spacing(2) + 30,
  },
  sectionTitle: {
    marginLeft: theme.spacing(4.5),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    height: 1,
    backgroundColor: '#D8D8D880',
  },
}))
