import React from 'react'

// components
import {
  Button,
  Typography,
  IconButton,
} from '@material-ui/core'

import { ReactComponent as SeeIcon } from '../../assets/Icons_See.svg'
import { ReactComponent as EditIcon } from '../../assets/Icons_Edit.svg'
import { ReactComponent as DeleteIcon } from '../../assets/icons-Delete.svg'
import { ReactComponent as CheckIcon } from '../../assets/Icons_Green_Check.svg'

export default function generate({
  t, classes, reorganizeItemFromId, onReorganize, onShowPhotos, onModify, onDelete, tableData,
}) {
  return [
    {
      name: 'controlItemType.name',
      label: t('common.typology'),
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => {
          const activeClassName = tableData.items[tableMeta.rowIndex].isArchived
            ? classes.disabled
            : ''
          return (
            <Typography variant="body2" className={activeClassName}>
              {tableData.items[tableMeta.rowIndex].controlItemType.name}
            </Typography>
          )
        },
      },
    },
    {
      name: 'controlItemGroup.name',
      label: t('common.group'),
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => {
          const activeClassName = tableData.items[tableMeta.rowIndex].isArchived
            ? classes.disabled
            : ''
          return (
            <Typography variant="body2" className={activeClassName}>
              {tableData.items[tableMeta.rowIndex].controlItemGroup.name}
            </Typography>
          )
        },
      },
    },
    {
      name: 'title',
      label: t('common.title'),
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => {
          const activeClassName = tableData.items[tableMeta.rowIndex].isArchived
            ? classes.disabled
            : ''
          return (
            <Typography variant="body2" className={activeClassName}>
              {tableData.items[tableMeta.rowIndex].title}
            </Typography>
          )
        },
      },
    },
    {
      name: 'description',
      label: t('common.description'),
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => {
          const activeClassName = tableData.items[tableMeta.rowIndex].isArchived
            ? classes.disabled
            : ''
          return (
            <Typography
              className={activeClassName}
              style={
              {
                wordBreak: 'break-all',
                fontSize: 15,
              }
            }
            >
              {val}
            </Typography>
          )
        },
      },
    },
    {
      name: 'isuzuPdi',
      label: t('control-items-list.isuzu-pdi-table-header'),
      options: {
        sort: false,
        customBodyRender: (val) => {
          if (val) {
            return (
              <CheckIcon />
            )
          }
          return ' '
        },
      },
    },
    {
      name: 'mandatoryPhoto',
      label: t('control-items-list.mandatory-photo-table-header'),
      options: {
        sort: false,
        customBodyRender: (val) => {
          if (val) {
            return (
              <CheckIcon />
            )
          }
          return ' '
        },
      },
    },
    {
      name: 'photos',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => {
          if (val && Array.isArray(val) && val.length > 0) {
            return (
              <IconButton
                onClick={() => onShowPhotos(tableMeta.rowIndex)}
                color="primary"
              >
                <SeeIcon />
              </IconButton>
            )
          }
          const activeClassName = tableData.items[tableMeta.rowIndex].isArchived
            ? classes.disabled
            : ''
          return (
            <Typography
              className={activeClassName}
              style={
            {
              wordBreak: 'break-all',
              fontSize: 15,
            }
          }
            >
              {t('common.noPhoto')}
            </Typography>
          )
        },
      },
    },
    {
      name: 'modify',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => {
          if (reorganizeItemFromId !== null) {
            return ''
          }
          return (
            <IconButton
              onClick={() => onModify(tableMeta.rowIndex)}
              color="primary"
            >
              <EditIcon />
            </IconButton>
          )
        },
      },
    },
    {
      name: 'delete',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => {
          if (reorganizeItemFromId !== null) {
            return ''
          }
          return (
            <IconButton
              onClick={() => onDelete(tableMeta.rowIndex)}
              color="primary"
            >
              <DeleteIcon />
            </IconButton>
          )
        },
      },
    },
    {
      name: 'id',
      label: ' ',
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => {
          let buttonText
          if (reorganizeItemFromId === null) {
            buttonText = t('common.reorganize')
          } else if (reorganizeItemFromId.id === val) {
            buttonText = t('common.cancel')
          } else {
            buttonText = t('common.here')
          }

          return (
            <Button
              className={classes.linkButton}
              variant="text"
              onClick={() => onReorganize(tableMeta.rowIndex)}
              color="primary"
            >
              {buttonText}
            </Button>
          )
        },
      },
    },
  ]
}
