import React from 'react'
import {
  SvgIcon,
} from '@material-ui/core'

const ControlItemsListsIcon = () => (
  <SvgIcon>
    <path fillRule="evenodd" clipRule="evenodd" d="M15 6.895H5v14.21h10V6.895zM3 5v18h14V5H3z" />
    <path d="M19 2.895H9V4H7V1h14v18h-3v-1.895h1V2.895z" />
    <path d="M6 9h8v2H6zM6 13h8v2H6zM6 17h6v2H6z" />
  </SvgIcon>
)

export default ControlItemsListsIcon
