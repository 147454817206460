import React from 'react'

import { DropzoneArea } from 'material-ui-dropzone'
import { useTranslation } from 'react-i18next'
import useStyles from './styles'

export default function ImagePicker({
  onChange,
  onDelete,
  initialImages,
}) {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <DropzoneArea
      onChange={onChange}
      acceptedFiles={['image/jpeg', 'image/png']}
      onDelete={onDelete}
      filesLimit={10}
      dropzoneText={t('create-control-item-dialog.dropzoneText')}
      previewText=""
      initialFiles={initialImages}
      alertSnackbarProps={{
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        autoHideDuration: 6000,
      }}
      showPreviewsInDropzone
      previewGridClasses={{
        container: classes.previewGridContainer,
        image: classes.previewGridImage,
      }}
      previewGridProps={
        {
          container: {
            alignContent: 'center',
          },
          item: {
            xs: 12,
          },
        }
      }
    />
  )
}
