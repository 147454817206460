import React from 'react'
import LRU from 'lru-cache'
import { stringify } from 'qs'

import axios from 'axios'
import config from '../config'
import AuthRetrier from '../api/authRetrier'

import ChassisDocumentsAPI from '../api/chassis/documents'
import ControlAPI from '../api/control'
import AutocompleteEmailsAPI from '../api/utils/autocomplete-emails'
import ClientAPI from '../api/client'
import PermissionsAPI from '../api/permissions'

const cacheOptions = {
  max: 100,
  maxAge: 1000 * 60, // 1 minute in miliseconds
}

const cache = new LRU(cacheOptions)

const DataApiContext = React.createContext()
const worldHTTPClient = axios.create()

let httpClient = null

const getAuthToken = () => {
  const tokens = JSON.parse(localStorage.getItem('tokens'))
  if (tokens) {
    const { token } = tokens
    return token
  }
  return null
}

const getHTTPClient = () => {
  if (!httpClient) {
    const headers = {
      'Content-Type': 'application/json',
    }
    const token = getAuthToken()
    if (token) {
      headers.Authorization = `Bearer ${token}`
    }
    httpClient = axios.create({
      baseURL: `${config.apiBaseUrl}/su`,
      headers,
    })
    AuthRetrier(httpClient)
  }
  return httpClient
}

const resetHTTPClient = () => {
  cache.reset()
  httpClient = null
}

function prepareUrlForQuery(resource, range, filters, sort, merge) {
  const { page, limit } = range || {}
  let query = {
    ...merge,
  }
  if (filters) {
    // eslint-disable-next-line
    for (const [key, value] of Object.entries(filters)) {
      query[`filters[${key}]`] = value
    }
  }
  if (page) {
    query = { ...query, 'range[page]': page }
  }
  if (limit) {
    query = { ...query, 'range[limit]': limit }
  }
  if (sort) {
    const { field, direction } = sort
    const parts = field.split('.')
    if (parts.length > 0) {
      const sortKey = parts.reduce((key, part) => `${key}[${part}]`, 'sort')
      query = {
        ...query,
        [sortKey]: direction,
      }
    }
  }

  const stringifiedQuery = stringify(query, { arrayFormat: 'brackets' })
  return [resource, stringifiedQuery].filter((el) => el).join('?')
}

function ApiDataProvider({ children }) {
  const dataProvider = {
    get: (resource, params) => {
      let url = `${resource}`
      if (params.query) {
        url += `?${stringify(params.query)}`
      }
      const cachedData = cache.get(url)
      if (cachedData) {
        return Promise.resolve(cachedData)
      }
      return getHTTPClient()({ url, method: 'GET' }).then((response) => {
        cache.set(url, response)
        return response
      })
    },
    post: (resource, params) => {
      cache.reset()
      const url = `${resource}`
      return getHTTPClient()({
        url,
        method: 'POST',
        data: JSON.stringify(params.data),
      })
    },
    put: (resource, params) => {
      cache.reset()
      const url = `${resource}`
      return getHTTPClient()({
        url,
        method: 'PUT',
        data: JSON.stringify(params.data),
      })
    },
    getList: (resource, params, merge) => {
      const url = prepareUrlForQuery(resource, params.range, params.filters, params.sort, merge)
      const cachedData = cache.get(url)
      if (cachedData) {
        return Promise.resolve(cachedData)
      }
      return getHTTPClient()({ url, method: 'GET' }).then((response) => {
        cache.set(url, response)
        return response
      })
    },
    getOne: (resource, params) => {
      const url = `${resource}/${params.id}`
      const cachedData = cache.get(url)
      if (cachedData) {
        return Promise.resolve(cachedData)
      }
      return getHTTPClient()({ url, method: 'GET' }).then((response) => {
        cache.set(url, response)
        return response
      })
    },
    update: (resource, params) => {
      cache.reset()
      return getHTTPClient()(
        {
          url: `${resource}/${params.id}/edit`,
          method: 'PUT',
          data: JSON.stringify(params.data),
        },
      )
    },
    updateMany: (resource, params) => Promise.all(
      params.ids.map((id) => getHTTPClient()({
        method: 'PUT',
        url: `${resource}/${id}/edit`,
        data: JSON.stringify(params.data),
      })),
    ),
    updateBulk: (resource, data) => {
      cache.reset()
      return getHTTPClient()(
        {
          url: resource,
          data: JSON.stringify(data),
          method: 'PUT',
        },
      )
    },
    create: (resource, params) => {
      cache.reset()
      return getHTTPClient()({
        url: `${resource}`,
        method: 'POST',
        data: JSON.stringify(params.data),
      })
    },
    delete: (resource, params) => {
      cache.reset()
      return getHTTPClient()(
        { url: `${resource}/${params.id}`, method: 'DELETE' },
      )
    },
    deleteMany: (resource, params) => Promise.all(
      params.ids.map((id) => getHTTPClient()({ url: `/${resource}/${id}`, method: 'DELETE' })),
    ),
    deleteBulk: (resource, body) => {
      cache.reset()
      return getHTTPClient()(
        {
          url: resource,
          data: JSON.stringify(body),
          method: 'DELETE',
        },
      )
    },
    upload: (params) => {
      const { file } = params

      return getHTTPClient()({
        url: '/upload',
        method: 'POST',
        data: {
          contentType: file.type
        }
      }).then((response) => {
        const formDataFields = response.data.formData.fields || {}
        const formData = new FormData();
        Object.keys(formDataFields).forEach((fieldName) => {
          const fieldValue = formDataFields[fieldName]
          formData.append(fieldName, fieldValue);
        })
        formData.append("file", file);
        return worldHTTPClient({
          url: response.data.formData.url,
          method: 'POST',
          data: formData,
        }).then(() => Promise.resolve({ photoUrl: response.data.url }))
      })
    },
    prepareUrlForQuery,
    generateExportUrl: (resource, params, type) => {
      const url = prepareUrlForQuery(`${resource}/${type}`, params.range, params.filters, params.sort, {
        token: getAuthToken(),
      })

      return `${config.apiBaseUrl}/exports/${url}`
    },
    generatePVAdmissionExportUrl: (controlId) => {
      if (typeof controlId !== 'number') {
        return null
      }
      return `${config.apiBaseUrl}/exports/control/${controlId}/pv-admission`
    },

    // New API context structure
    chassis: {
      documents: {
        get: (chassisId) => ChassisDocumentsAPI.get(chassisId, getHTTPClient()),
        upload: (response, file) => ChassisDocumentsAPI.upload(response, file, worldHTTPClient),
        requestUpload: (chassisId) => ChassisDocumentsAPI.requestUpload(chassisId, getHTTPClient()),
        post: (chassisId, type, url, originalName) => ChassisDocumentsAPI.post(
          chassisId,
          type,
          url,
          originalName,
          getHTTPClient(),
        ),
        deleteDocuments: (chassisId, types) => ChassisDocumentsAPI.deleteDocuments(
          chassisId,
          types,
          getHTTPClient(),
        ),
        downloadDocuments: (chassisId, types) => ChassisDocumentsAPI.downloadDocuments(
          chassisId,
          types, getHTTPClient(),
        ),
      },
    },

    control: {
      emailResult: (controlId, emails, sendTo) => ControlAPI.emailResult(
        controlId,
        emails,
        sendTo,
        getHTTPClient(),
      ),
      getItems: (controlId, groupFormat) => ControlAPI.getItems(
        controlId,
        groupFormat,
        getHTTPClient(),
      ),
    },

    utils: {
      autocompleteEmails: () => AutocompleteEmailsAPI.autocompleteEmails(
        getHTTPClient(),
      ),
    },

    cache,

    client: {
      getList: (resource, params) => ClientAPI.getList(resource, params, getHTTPClient()),
      post: (name, address) => ClientAPI.post(
        name,
        address,
        getHTTPClient(),
      ),
      put: (clientId, name, address) => ClientAPI.put(
        clientId,
        name,
        address,
        getHTTPClient(),
      ),
    },

    permissions: {
      get: () => PermissionsAPI.get(getHTTPClient()),
      post: (role, permission) => PermissionsAPI.post(
        role,
        permission,
        getHTTPClient(),
      ),
      deletePermission: (role, permission) => PermissionsAPI.deletePermission(
        role,
        permission,
        getHTTPClient(),
      ),
    },
  }

  return (
    <DataApiContext.Provider value={{ dataProvider }}>
      {children}
    </DataApiContext.Provider>
  )
}

function useDataApi() {
  const context = React.useContext(DataApiContext)
  if (context === undefined) {
    throw new Error('useDataState must be used within a ApiDataProvider')
  }
  return context
}

export { ApiDataProvider, useDataApi, resetHTTPClient }
