import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  filtersContainer: {
    background: 'white',
    borderRadius: '5px',
    boxShadow: '0 1px 5px 0 rgba(227, 227, 236, 0.8)',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  filterContainerTitleText: {
    color: theme.palette.captGrey.main,
  },
  selector: {
    width: '100%',
  },
  tableContainer: {
    background: 'white',
    borderRadius: '5px',
    boxShadow: '0 1px 5px 0 rgba(227, 227, 236, 0.8)',
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  table: {
    boxShadow: '0 0px 0px 0 rgba(0, 0, 0, 0)',
  },
  textField: {
    width: '100%',
  },
  linkButton: {
    textTransform: 'capitalize',
  },
  linkButtonRed: {
    color: 'red',
  },
  linkButtonGreen: {
    color: 'green',
  },
  linkButtonOrange: {
    color: '#FFA500',
  },
  controlDateParent: {
    display: 'flex',
    alignItems: 'center',
  },
  controlDate: {
    padding: '0px 8px',
  },
  greenText: {
    color: 'green',
  },
  orangeText: {
    color: 'orange',
  },
  actionsContainer: {
    marginTop: '10px',
  },
  selectionButton: {
    height: '100%',
    textTransform: 'none',
    backgroundColor: 'white',
    '& #text': {
      paddingLeft: '10px',
    },
    '& #icon': {
      '& path': {
        fill: theme.palette.primary.main,
      },
    },
    '&.selected #icon': {
      '& path': {
        fill: theme.palette.secondary.main,
      },
    },
    '&.selected': {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
    },
  },
}))
