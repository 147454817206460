import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  TextField,
  Grid,
} from '@material-ui/core'

import BaseDialog from '../../BaseDialog'

import { useDataApi } from '../../../context/DataApiContext'
import { useNotificationsProvider } from '../../../context/NotificationsContext'
import AutoCompleteInput from '../../AutoCompleteInput'
import useAutocompleteFreesolo from '../../../hooks/autocompleteFreesolo'

export default function ModifyClientDialog({
  open,
  onClose,
  onModified,
  clientToModify,
}) {
  const { t } = useTranslation()

  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [name, setName] = useState('')
  const addressAutocompleteState = useAutocompleteFreesolo()

  const resetState = () => {
    // Clean form
    setLoading(false)
    setError('')

    if (clientToModify) {
      setName(clientToModify.name || '')
      addressAutocompleteState.set({
        id: clientToModify.address.id,
        title: clientToModify.address.label,
      })
    } else {
      setName('')
      addressAutocompleteState.clear()
    }
  }

  const onCloseDialog = (event, reason) => {
    if ((reason === 'backdropClick') || (reason === 'escapeKeyDown')) {
      onClose()
    } else {
      resetState()
      onClose()
    }
  }

  const onConfirm = () => {
    if (name.trim().length === 0) {
      setError('name_required')
      return
    }

    let addressObj = addressAutocompleteState.toObject()
    if (!addressObj || addressObj.title.trim().length === 0) {
      setError('address_required')
      return
    }

    setLoading(true)
    const postData = {
      id: addressObj.id,
      name: addressObj.title
    }
    const clientId = clientToModify.id

    dataProvider.client.put(clientId, name, postData)
      .then(() => {
        dataProvider.cache.reset()
        onModified()
        showNotification('success', t('modify-client-dialog.success'))
      }).catch(() => {
        showNotification('error', t('errors.api-error'))
      }).then(() => resetState())
  }

  useEffect(() => {
    if (clientToModify) {
      setName(clientToModify.name || '')
      addressAutocompleteState.set({
        id: clientToModify.address.id,
        title: clientToModify.address.label,
      })
    }
    // eslint-disable-next-line
  }, [clientToModify])

  return (
    <BaseDialog
      open={open}
      onClose={onCloseDialog}
      onConfirm={onConfirm}
      title={t('modify-client-dialog.title')}
      loading={loading}
    >
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <TextField
            required
            error={error === 'name_required'}
            helperText={t('common.required')}
            id="name"
            label={t('create-client-dialog.name')}
            fullWidth
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </Grid>
        <Grid item>
          <AutoCompleteInput
            onChange={addressAutocompleteState.onChange}
            value={addressAutocompleteState.value}
            label={t('create-client-dialog.address')}
            resource="/clients/address-autocomplete"
            error={error === 'address_required'}
            helperText={t('common.required')}
            required
            canDelete
            freeSolo
            onInputChange={addressAutocompleteState.onInputChange}
          />
        </Grid>
      </Grid>
    </BaseDialog>
  )
}
