import React from 'react'

// components
import {
  Typography,
} from '@material-ui/core'
import dateFnsFormat from 'date-fns/format'
import enums from '../../utils/enums'

export default function generate({
  t, classes, tableData, onControlClick,
}) {
  return [
    {
      name: 'vehicle.vehicleType.title',
      label: t('controls-list.vehicle-type-table-header'),
    },
    {
      name: 'vehicle.nSerie',
      label: t('controls-list.n-serie-table-header'),
    },
    {
      name: 'controlType.name',
      label: t('controls-list.control-type-table-header'),
    },
    {
      name: 'vehicle.client.name',
      label: t('common.client'),
      options: {
        customBodyRender: (val, tableMeta) => (
          val ? val : t('common.not-set')
        ),
      },
    },
    {
      name: 'controlDate',
      label: t('controls-list.dates-table-header'),
      options: {
        customBodyRender: (val, tableMeta) => {
          const formatedDate = dateFnsFormat(
            Date.parse(val),
            'dd/MM/yyyy',
          )
          switch (tableData.items[tableMeta.rowIndex].conformity) {
            case enums.CONFORMITY.INVALID:
              return (
                <Typography color="error">
                  {formatedDate}
                </Typography>
              )
            case enums.CONFORMITY.VALID:
              return (
                <Typography className={classes.greenText}>
                  {formatedDate}
                </Typography>
              )
            case enums.CONFORMITY.WAITING_CONTROL:
              return (
                <Typography className={classes.orangeText}>
                  {formatedDate}
                </Typography>
              )
            default:
              return (
                <Typography>
                  {formatedDate}
                </Typography>
              )
          }
        },
      },
    }
  ]
}
