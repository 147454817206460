const Permissions = Object.freeze({
  PAGE_DASHBOARD_ROOT: 'page-dashboard:root',
  PAGE_VEHICLES_TYPE_ROOT: 'page-vehicles-type:root',
  PAGE_CONTROL_ITEMS_LISTS_ROOT: 'page-control-items-lists:root',
  PAGE_CONTROLS_ROOT: 'page-controls:root',
  PAGE_VEHICLES_ROOT: 'page-vehicles:root',
  PAGE_CLIENTS_ROOT: 'page-clients:root',
  PAGE_USERS_ROOT: 'page-users:root',
  APPLICATION_ACCESS: 'application-access',
})

const permissionTree = {
  [Permissions.PAGE_DASHBOARD_ROOT]: [],
  [Permissions.PAGE_VEHICLES_TYPE_ROOT]: [],
  [Permissions.PAGE_CONTROL_ITEMS_LISTS_ROOT]: [],
  [Permissions.PAGE_CONTROLS_ROOT]: [],
  [Permissions.PAGE_VEHICLES_ROOT]: [],
  [Permissions.PAGE_CLIENTS_ROOT]: [],
  [Permissions.PAGE_USERS_ROOT]: [],
  [Permissions.APPLICATION_ACCESS]: [],
}

function translate(permission) {
  return permission // TODO: translate when/if this project needs this feature
}

export {
  Permissions, translate, permissionTree,
}
