import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  TextField,
  Grid,
  Checkbox,
  Typography,
} from '@material-ui/core'
import BaseDialog from '../../BaseDialog'

import { useDataApi } from '../../../context/DataApiContext'
import { useNotificationsProvider } from '../../../context/NotificationsContext'
import ImagePicker from '../../ImagePicker'
import AutoCompleteInput from '../../AutoCompleteInput'

export default function ModifyControlItemDialog({
  open,
  onClose,
  onModified,
  originalControlItem,
}) {
  const { t } = useTranslation()

  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [controlItemType, setControlItemType] = useState(null)
  const [controlItemGroup, setControlItemGroup] = useState(null)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [images, setImages] = useState([])
  const [isuzuPdi, setIzuzuPdi] = useState(false)
  const [mandatoryPhoto, setMandatoryPhoto] = useState(false)

  useEffect(() => {
    if (!originalControlItem) {
      return
    }

    setControlItemType({
      id: originalControlItem.controlItemType.id,
      title: originalControlItem.controlItemType.name
    })
    setControlItemGroup({
      id: originalControlItem.controlItemGroup.id,
      title: originalControlItem.controlItemGroup.name
    })
    setTitle(originalControlItem.title)
    setDescription(originalControlItem.description)
    setIzuzuPdi(originalControlItem.isuzuPdi)
    setMandatoryPhoto(originalControlItem.mandatoryPhoto)
  }, [originalControlItem])

  const resetState = () => {
    // Clean form
    setControlItemType(null)
    setControlItemGroup(null)
    setTitle('')
    setDescription('')
    setIzuzuPdi(false)
    setMandatoryPhoto(false)
    setImages([])
    setError(null)
    setLoading(false)
  }

  const onCloseDialog = (event, reason) => {
    if ((reason === 'backdropClick') || (reason === 'escapeKeyDown')) {
      onClose()
    } else {
      resetState()
      onClose()
    }
  }

  const onConfirm = () => {
    if (!controlItemType) {
      setError('controlItemType_required')
      return
    }

    if (!controlItemGroup) {
      setError('controlItemGroup_required')
      return
    }

    if (title.length === 0) {
      setError('title_required')
      return
    }

    if (description.length === 0) {
      setError('description_required')
      return
    }

    const data = {}
    if (originalControlItem.controlItemType.id !== controlItemType.id) {
      data.controlItemTypeId = controlItemType.id
    }
    if (originalControlItem.controlItemGroup.id !== controlItemGroup.id) {
      data.controlItemGroupId = controlItemGroup.id
    }
    if (originalControlItem.title !== title) {
      data.title = title
    }
    if (originalControlItem.description !== description) {
      data.description = description
    }
    if (originalControlItem.isuzuPdi !== isuzuPdi) {
      data.isuzuPdi = isuzuPdi
    }
    if (originalControlItem.mandatoryPhoto !== mandatoryPhoto) {
      data.mandatoryPhoto = mandatoryPhoto
    }

    setLoading(true)

    // Only upload new images
    const uploadedImageNames = originalControlItem.photos.map((url) => url.substring(url.lastIndexOf('/') + 1))
    Promise.all(images.map((image) => {
      const uploadedImageUrlIdx = uploadedImageNames.indexOf(image.name)
      if (uploadedImageUrlIdx !== -1) {
        return originalControlItem.photos[uploadedImageUrlIdx]
      }
      return dataProvider.upload({ file: image })
        .then((response) => response.photoUrl)
    }))
      .then((urls) => {
        data.photos = urls

        return dataProvider.update('control-items', { data, id: originalControlItem.id }).then(() => {
          showNotification('success', t('modify-control-item-dialog.success'))
          onModified()
        }).catch(() => {
          showNotification('error', t('errors.api-error'))
        })
      }).catch(() => {
        showNotification('error', t('errors.api-error'))
      }).then(() => resetState())
  }

  return (
    <BaseDialog
      open={open}
      onClose={() => {
        onCloseDialog()
      }}
      onConfirm={onConfirm}
      title={t('modify-control-item-dialog.title')}
      loading={loading}
      openButtonTitle={t('common.modify')}
    >
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <AutoCompleteInput
            onChange={setControlItemType}
            value={controlItemType}
            label={t('create-control-item-dialog.types')}
            resource="/control-items/type-autocomplete"
            error={error === 'controlItemType_required'}
            helperText={t('common.required')}
            query={{ q: '' }}
            required
            canDelete
          />
        </Grid>
        <Grid item>
          <AutoCompleteInput
            onChange={setControlItemGroup}
            value={controlItemGroup}
            label={t('create-control-item-dialog.groups')}
            resource="/control-items/group-autocomplete"
            error={error === 'controlItemGroup_required'}
            helperText={t('common.required')}
            query={{ q: '' }}
            required
            canDelete
          />
        </Grid>
        <Grid item>
          <TextField
            required
            error={error === 'title_required'}
            helperText={t('common.required')}
            id="title"
            label={t('common.title')}
            fullWidth
            value={title}
            onChange={(event) => setTitle(event.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            required
            error={error === 'description_required'}
            helperText={t('common.required')}
            id="description"
            label={t('common.description')}
            multiline
            fullWidth
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          />
        </Grid>
        <Grid item>
          <Grid container direction="row" alignItems='center' justifyContent='space-between'>
            <Grid item>
              <Typography>{t('common.isuzuPdi')}</Typography>
            </Grid>
            <Grid item>
              <Checkbox color='primary' onChange={(evt) => setIzuzuPdi(evt.target.checked)} checked={isuzuPdi} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row" alignItems='center' justifyContent='space-between'>
            <Grid item>
              <Typography>{t('create-control-item-dialog.mandatory-photo')}</Typography>
            </Grid>
            <Grid item>
              <Checkbox color='primary' onChange={(evt) => setMandatoryPhoto(evt.target.checked)} checked={mandatoryPhoto} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <ImagePicker
            onChange={setImages}
            initialImages={originalControlItem.photos}
          />
        </Grid>
      </Grid>
    </BaseDialog>
  )
}
