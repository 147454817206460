import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Grid,
} from '@material-ui/core'

import BaseDialog from '../../BaseDialog'

import { useDataApi } from '../../../context/DataApiContext'
import { useNotificationsProvider } from '../../../context/NotificationsContext'
import AutoCompleteInput from '../../AutoCompleteInput'

export default function CreateControlItemsListDialog({
  open,
  onClose,
  onCreated,
}) {
  const { t } = useTranslation()

  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [vehicleType, setVehicleType] = useState([])
  const [controlType, setControlType] = useState(null)

  const resetState = () => {
    // Clean form
    setLoading(false)
    setError('')
    setVehicleType([])
    setControlType(null)
  }

  const onCloseDialog = (event, reason) => {
    if ((reason === 'backdropClick') || (reason === 'escapeKeyDown')) {
      onClose()
    } else {
      resetState()
      onClose()
    }
  }

  const onConfirm = () => {
    if (!Array.isArray(vehicleType) || vehicleType.length === 0) {
      setError('vehicleType_required')
      return
    }

    if (!controlType) {
      setError('controlType_required')
      return
    }

    const data = {
      vehicleTypeIds: vehicleType.map((el) => el.id),
      controlTypeId: controlType.id,
    }

    setLoading(true)
    dataProvider.create('control-items-lists', { data }).then(() => {
      dataProvider.cache.reset()
      setLoading(false)
      showNotification('success', t('create-control-items-list-dialog.success'))
      onCreated()
      resetState()
    }).catch((err) => {
      setLoading(false)
      if (err.response.data.validationErrors && err.response.data.validationErrors.length > 0) {
        showNotification('error', err.response.data.validationErrors[0].errorMessage)
        setError(`${err.response.data.validationErrors[0].field}_invalid`)
      } else {
        showNotification('error', t('errors.api-error'))
      }
    })
  }
  return (
    <BaseDialog
      open={open}
      onClose={onCloseDialog}
      onConfirm={onConfirm}
      title={t('create-control-items-list-dialog.title')}
      loading={loading}
    >
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <AutoCompleteInput
            error={error === 'vehicleType_required'}
            onChange={setVehicleType}
            label={t('create-control-items-list-dialog.model')}
            resource="vehicle-type/autocomplete"
            value={vehicleType}
            required
            multiple
            canDelete
          />
        </Grid>
        <Grid item>
          <AutoCompleteInput
            error={error === 'controlType_required'}
            onChange={setControlType}
            label={t('create-control-items-list-dialog.type')}
            resource="control-types/autocomplete"
            value={controlType}
            required
            canDelete
          />
        </Grid>
      </Grid>
    </BaseDialog>
  )
}
