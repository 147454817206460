import React from 'react'

// components
import {
  Button,
} from '@material-ui/core'

export default function generate({
  t, classes, onSeeVehicles,
}) {
  return [
    {
      name: 'name',
      label: t('clients-list.name-table-header'),
    },
    {
      name: 'vehiclesCount',
      label: t('clients-list.vehicle-count-table-header'),
      options: {
        sort: false,
        customBodyRender: (val, tableMeta) => (
          <Button
            className={classes.linkButton}
            variant="text"
            onClick={() => onSeeVehicles(tableMeta.rowIndex)}
            color="primary"
          >
            {val}
          </Button>
        ),
      },
    },
    {
      name: 'address.label',
      label: t('clients-list.address-table-header'),
      options: {
        sort: false,
      },
    }
  ]
}
