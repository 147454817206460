import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  linkButton: {
    textTransform: 'capitalize',
  },
  disabled: {
    color: '#B9B9B9',
  },
  filtersContainer: {
    background: 'white',
    borderRadius: '5px',
    boxShadow: '0 1px 5px 0 rgba(227, 227, 236, 0.8)',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  tableContainer: {
    marginTop: theme.spacing(2),
  },
  undoButton: {
    height: '100%',
    backgroundColor: 'white',
  },
  allItemsButton: {
    height: '100%',
    textTransform: 'none',
    backgroundColor: 'white',
    '& #text': {
      paddingLeft: '10px',
    },
    '& #icon': {
      '& path': {
        fill: theme.palette.primary.main,
      },
    },
    '&.selected #icon': {
      '& path': {
        fill: theme.palette.secondary.main,
      },
    },
    '&.selected': {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
    },
  },
  menuItemRow: {
    padding: '10px',
    '& #icon': {
      '& path': {
        fill: theme.palette.primary.main,
      },
    },
  },
  actionsContainer: {
    marginTop: '10px',
  },
}))
