import React, { useState, useEffect, useCallback } from 'react'

// components
import { useTranslation } from 'react-i18next'
import {
  Grid,
  Button,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import Loading from '../../components/Loading'
import Table from '../../components/Table'
import PageTitle from '../../components/PageTitle'
import useStyles from './styles'
import { useDataApi } from '../../context/DataApiContext'
import { toQueryString } from '../../utils/query'
import { useNotificationsProvider } from '../../context/NotificationsContext'
import { useConfirmationDialogProvider } from '../../context/ConfirmationDialogContext'
import CreateControlItemsListDialog from '../../components/ControlItemsLists/CreateControlItemsListDialog'
import ModifyControlItemsListDialog from '../../components/ControlItemsLists/ModifyControlItemsListDialog'
import generateColumns from './columns'

import { ReactComponent as TrashIcon } from '../../assets/icons-Delete.svg'
import { ReactComponent as EditIcon } from '../../assets/Icons_Edit.svg'

export default function VehicleList({ history }) {
  const { t } = useTranslation()
  const theme = useTheme()
  const fullWidth = useMediaQuery(theme.breakpoints.down('xs'))

  const classes = useStyles()
  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()
  const { showConfirmationDialog } = useConfirmationDialogProvider()

  const [openCreateListDialog, setOpenCreateListDialog] = useState(false)
  const [listToModify, setListToModify] = useState({ open: false })
  const [selection, setSelection] = useState({ rowsSelected: [] })

  const [loading, setLoading] = useState(true)
  const [tableState, setTableState] = useState({
    page: 0, sort: null, filters: null,
  })
  const [tableData, setTableData] = useState(null)

  const refreshData = useCallback((keepPage = false) => {
    if (keepPage) {
      setTableState(state => ({ ...state }))
    } else {
      setTableState({
        page: 0, sort: null, filters: null,
      })
    }
  }, [setTableState])

  useEffect(() => {
    dataProvider.getList(
      'control-items-lists',
      {
        range: { page: tableState.page + 1 },
        filters: tableState.filters,
        sort: tableState.sort,
      },
    ).then((response) => {
      setTableData(response.data)
      setLoading(false)
    }).catch(() => showNotification('error', t('errors.api-error')))
    // eslint-disable-next-line
  }, [tableState])

  const performDelete = useCallback((rowIndexes) => {
    if (!rowIndexes || rowIndexes.length === 0) {
      return
    }
    let dialogTitle = t('control-points-list.confirm-list-deletion.multiple')
    const idsToDelete = tableData.items
      .filter((item, index) => rowIndexes.indexOf(index) !== -1)
      .map((item) => item.id)

    if (idsToDelete.length === 1) {
      const { name } = tableData.items[rowIndexes[0]]
      dialogTitle = t('control-points-list.confirm-list-deletion.single', { name })
    }
    showConfirmationDialog(
      dialogTitle,
      () => {
        dataProvider
          .deleteBulk('/control-items-lists/bulk', idsToDelete)
          .then(
            () => {
              // Sync row selection by removing deleted elements
              // eslint-disable-next-line max-len
              const filteredSelection = selection.rowsSelected.filter((index) => rowIndexes.indexOf(index) === -1)
              setSelection({ rowsSelected: filteredSelection })

              showNotification('success', t('common.success'))
              refreshData(true)
            },
          ).catch(
            () => showNotification('error', t('errors.api-error')),
          )
      },
    )
    // eslint-disable-next-line max-len
  }, [dataProvider, refreshData, selection.rowsSelected, showConfirmationDialog, showNotification, t, tableData])

  const onModify = () => {
    if (selection && selection.rowsSelected && selection.rowsSelected.length === 1) {
      const rowIndex = selection.rowsSelected[0]
      const list = tableData.items[rowIndex]
      if (list) {
        setListToModify({ open: true, list, duplicate: false })
      }
    }
  }

  const onDeleteSelection = () => {
    if (selection && selection.rowsSelected) {
      performDelete(selection.rowsSelected)
    }
  }

  const onDuplicate = () => {
    if (selection && selection.rowsSelected && selection.rowsSelected.length === 1) {
      const rowIndex = selection.rowsSelected[0]
      const list = tableData.items[rowIndex]
      if (list) {
        setListToModify({ open: true, list, duplicate: true })
      }
    }
  }

  const tableColumns = generateColumns({
    t,
    classes,
    onSee: (idx) => {
      const controlListId = tableData.items[idx].id
      history.push(`/control-items-lists/${controlListId}`)
    },
    onSeeVehicles: (idx) => {
      const list = tableData.items[idx]
      if (list) {
        const vehicleTypeFilter = list.vehicleTypes.map((vehicleType) => {
          return {
            id: vehicleType.id,
            title: vehicleType.title
          }
        })
        const stateControlTypeFilter = {
          id: list.controlType.id,
          title: list.controlType.name
        }
        const queryParams = {
          vehicleTypes: vehicleTypeFilter,
          stateControlType: stateControlTypeFilter
        }
        const queryString = toQueryString(queryParams)
        history.push(`/vehicles?${queryString}`)
      }
    },
  })

  if (loading) {
    return <Loading />
  }
  return (
    <>
      <CreateControlItemsListDialog
        open={openCreateListDialog}
        onClose={() => setOpenCreateListDialog(false)}
        onCreated={() => {
          refreshData(true)
          setOpenCreateListDialog(false)
        }}
      />
      <ModifyControlItemsListDialog
        open={listToModify.open}
        onClose={() => {
          setListToModify((state) => ({ ...state, open: false }))
        }}
        listToModify={listToModify.list}
        duplicate={!!listToModify.duplicate}
        onModified={() => {
          refreshData(true)
          setListToModify((state) => ({ ...state, open: false }))
        }}
      />
      <Grid container direction="column">
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={12} sm={6}>
            <PageTitle title={t('control-points-list.title')} />
          </Grid>
          <Grid item container sm={6} justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              fullWidth={fullWidth}
              onClick={() => setOpenCreateListDialog(true)}
            >
              {t('control-points-list.add')}
            </Button>
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item>
            <Button
              disabled={!(selection && selection.rowsSelected && selection.rowsSelected.length === 1)}
              variant="contained"
              fullWidth={fullWidth}
              className={[classes.selectionButton].join(' ')}
              onClick={() => onModify()}
            >
              <EditIcon id="icon" />
              <span id="text">{t('common.modify')}</span>
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={!(selection && selection.rowsSelected && selection.rowsSelected.length > 0)}
              variant="contained"
              fullWidth={fullWidth}
              className={[classes.selectionButton].join(' ')}
              onClick={() => onDeleteSelection()}
            >
              <TrashIcon id="icon" />
              <span id="text">
                {selection && selection.rowsSelected && selection.rowsSelected.length > 1
                  ? t('control-points-list.delete-list.multiple')
                  : t('control-points-list.delete-list.single')}
              </span>
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={!(selection && selection.rowsSelected && selection.rowsSelected.length === 1)}
              variant="contained"
              fullWidth={fullWidth}
              className={[classes.selectionButton].join(' ')}
              onClick={() => onDuplicate()}
            >
              {t('common.duplicate')}
            </Button>
          </Grid>
        </Grid>
        <Grid item className={classes.tableContainer} xs={12}>
          <Table
            id="control-points-list"
            columns={tableColumns}
            data={tableData}
            page={tableState.page}
            sort={tableState.sort}
            onChangePage={(currentPage) => {
              setTableState({ ...tableState, page: currentPage })
            }}
            onColumnSortChange={(changedColumn, direction) => {
              const newSort = {
                field: changedColumn,
                direction: direction.toUpperCase(),
              }
              setTableState({ ...tableState, sort: newSort })
            }}
            selectableRows="multiple"
            rowsSelected={selection.rowsSelected}
            onRowSelectionChange={(rowsSelected, allRows) => {
              setSelection({ rowsSelected: allRows.map((row) => row.dataIndex) })
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}
