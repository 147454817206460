import React, { useState } from 'react'
import {
  Grid,
  CircularProgress,
  Button,
  Typography,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { withRouter } from 'react-router-dom'
// styles
import { useTranslation } from 'react-i18next'
import useStyles from './styles'

// logo
import logo from '../../assets/logo.png'

// context
import authApi from '../../api/authApi'

function PasswordRecovery({ history }) {
  const classes = useStyles()

  // global
  const { t } = useTranslation()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  // local
  const [isLoading, setIsLoading] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  // const [error, setError] = useState(null)
  const [email, setEmail] = useState('')

  const recoverPassword = () => {
    setIsLoading(true)
    authApi.resetPassword(email).then(() => {
      setIsLoading(false)
      setOpenDialog(true)
    })
  }

  return (
    <Grid container justifyContent="center" className={classes.container}>
      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false)
          history.push('/login')
        }}
        fullScreen={fullScreen}
      >
        <DialogContent>
          <Typography variant="body1">
            {t('password-recovery-view.success')}
          </Typography>
          <DialogActions className={classes.actionsContainer}>
            <Button
              onClick={() => {
                setOpenDialog(false)
                history.push('/login')
              }}
              color="primary"
              variant="contained"
            >
              {t('password-recovery-view.confirm')}
            </Button>
          </DialogActions>

        </DialogContent>
      </Dialog>
      <Grid
        item
        container
        xs={11}
        sm={6}
        md={4}
        lg={3}
        xl={2}
        className={classes.formContainer}
        spacing={5}
      >
        <Grid item container xs={10}>
          <Grid item className={classes.logoContainer}>
            <img src={logo} alt={logo} className={classes.logo} />
          </Grid>
          <Grid item className={classes.messageContainer}>
            <Typography variant="body2" align="justify">
              {t('password-recovery-view.message')}
            </Typography>
          </Grid>
          <Grid item className={classes.form}>
            <>
              <TextField
                id="email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                margin="normal"
                label={t('login-view.email')}
                type="email"
                fullWidth
                required
              />
              <Grid item container className={classes.formButtons}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    disabled={
                    email.length === 0
                  }
                    onClick={() => recoverPassword()}
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                  >
                    {t('common.send')}
                  </Button>
                )}
              </Grid>
            </>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withRouter(PasswordRecovery)
