export default function generate({
  t,
}) {
  return [
    {
      name: 'vehicleType.title',
      label: t('vehicle-types-list.model'),
    },
    {
      name: 'nSerie',
      label: t('common.nSerie'),
      options: {
        customBodyRender: (val, tableMeta) => (
          val ? val : t('common.not-available')
        ),
      },
    },
    {
      name: 'nIntern',
      label: t('common.nIntern'),
    },
    {
      name: 'plate',
      label: t('common.plate'),
      options: {
        customBodyRender: (val, tableMeta) => (
          val ? val : t('common.not-available')
        ),
      },
    },
    {
      name: 'client.name',
      label: t('common.client'),
      options: {
        customBodyRender: (val, tableMeta) => (
          val ? val : t('common.not-set')
        ),
      },
    },
  ].filter((column) => !!column) // Filter nulls
}
