import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react'

// components
import { useTranslation } from 'react-i18next'
import {
  useParams,
} from 'react-router-dom'
import {
  Grid,
  Button,
  MenuItem,
  Typography,
  TextField,
  FormControlLabel,
  useMediaQuery,
  useTheme,
  IconButton,
  Checkbox,
} from '@material-ui/core'
import dateFnsFormat from 'date-fns/format'
import Loading from '../../components/Loading'
import PageTitle from '../../components/PageTitle'
import useStyles from './styles'
import CreateControlItemDialog from '../../components/ControlItemsList/CreateControlItemDialog'
import Table from '../../components/Table'
import generateColumns from './columns'
import AutoCompleteInput from '../../components/AutoCompleteInput'
import ModifyControlItemDialog from '../../components/ControlItemsList/ModifyControlItemDialog'
import PhotoGaleryDialog from '../../components/PhotoGaleryDialog'

import { useNotificationsProvider } from '../../context/NotificationsContext'
import { useDataApi } from '../../context/DataApiContext'
import { useConfirmationDialogProvider } from '../../context/ConfirmationDialogContext'
import { useQueryState } from '../../utils/query'

import { ReactComponent as UndoIcon } from '../../assets/Icons_undo.svg'
import { ReactComponent as AddOutlineIcon } from '../../assets/Icons_Add_Outline.svg'
import { ReactComponent as TrashIcon } from '../../assets/icons-Delete.svg'
import { ReactComponent as BackIcon } from '../../assets/Icons_Back.svg'

export default function ControlItemsList({ history }) {
  const { t } = useTranslation()

  const theme = useTheme()
  const fullWidth = useMediaQuery(theme.breakpoints.down('xs'))
  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()
  const { showConfirmationDialog } = useConfirmationDialogProvider()
  const classes = useStyles()
  const { id: controlItemsListId } = useParams()

  const [itemsList, setItemsList] = useState({})
  const [loading, setLoading] = useState(true)

  const [openCreateControlItemDialog, setOpenCreateControlItemDialog] = useState({
    dialogOpen: false,
    createBelowItemWithId: null,
  })
  const [openModifyControlItemDialog, setOpenModifyControlItemDialog] = useState(false)
  const [openPhotoDialog, setOpenPhotoDialog] = useState(false)
  const [photoDialogUrls, setPhotoDialogUrls] = useState([])
  const [controlItemToModify, setControlItemToModify] = useState(false)
  const [reorganizeItemFromId, setReorganizeItemFromId] = useState(null)
  const [reorganizeOperations, setReorganizeOperations] = useState([])
  const [selection, setSelection] = useState({ rowsSelected: [] })

  const resource = `control-items-lists/${controlItemsListId}/items`

  const [controlItemType, setControlItemType] = useQueryState({ paramName: 'controlItemType', history })
  const [controlItemGroup, setControlItemGroup] = useQueryState({ paramName: 'controlItemGroup', history })
  const [q, setQ] = useQueryState({ paramName: 'q', defaultValue: '', history })
  const [iPdi, setIPdi] = useQueryState({ paramName: 'iPdi', history })
  const [isArchived, setIsArchived] = useQueryState({ paramName: 'isArchived', history })

  const computedFilters = useMemo(() => {
    const filtersObj = {
      controlItemTypeId: controlItemType ? controlItemType.id : null,
      controlItemGroupId: controlItemGroup ? controlItemGroup.id : null,
      q,
      all: true,
      isuzuPdi: iPdi === '1' ? true : undefined,
      isArchived: isArchived === '1' ? true : undefined,
    }
    // remove nulls
    Object.keys(filtersObj).forEach(
      (key) => filtersObj[key] === null && delete filtersObj[key],
    )
    return filtersObj
  }, [controlItemType, controlItemGroup, q, iPdi, isArchived])

  const [loadingData, setLoadingData] = useState(true)
  const [tableSort, setTableSort] = useQueryState({ paramName: 'sort', history })
  const [tablePage, setTablePage] = useQueryState({
    paramName: 'page', defaultValue: 0, history,
  })
  const [tableState, setTableState] = useState({
    page: tablePage, sort: tableSort, filters: computedFilters,
  })
  const [tableData, setTableData] = useState(null)

  useEffect(() => {
    dataProvider.getList(
      resource,
      {
        range: { page: tableState.page + 1 },
        filters: tableState.filters,
        sort: tableState.sort,
      },
    ).then((response) => {
      setTableData(response.data)
      setLoadingData(false)
    }).catch(() => showNotification('error', t('errors.api-error')))

    setTablePage(tableState.page)
    setTableSort(tableState.sort)
    // eslint-disable-next-line
  }, [tableState])

  const updateFilters = useCallback(() => {
    setTableState((state) => ({ ...state, page: 0, filters: computedFilters }))
  }, [computedFilters])

  const clearFilters = useCallback(() => {
    setControlItemType(null)
    setControlItemGroup(null)
    setQ('')
    setIPdi('0')
    setTableState((state) => ({ ...state, page: 0, filters: null }))
  }, [setControlItemType, setControlItemGroup, setQ, setIPdi])

  useEffect(() => {
    dataProvider.getList(`control-items-lists/${controlItemsListId}`, {})
      .then((response) => {
        setItemsList(response.data)
        setLoading(false)
      })
      .catch(() => showNotification('error', t('errors.api-error')))
    // eslint-disable-next-line
  }, [])

  const refreshData = useCallback((keepPage = false) => {
    if (keepPage) {
      setTableState({ ...tableState })
    } else {
      dataProvider.cache.reset()
      clearFilters()
    }
  }, [dataProvider.cache, tableState, clearFilters])

  const onShowPhotos = (rowIndex) => {
    const { photos } = tableData.items[rowIndex]
    setPhotoDialogUrls(photos)
    setOpenPhotoDialog(true)
  }

  const onModify = (rowIndex) => {
    setControlItemToModify(tableData.items[rowIndex])
    setOpenModifyControlItemDialog(true)
  }

  const performDelete = useCallback((rowIndexes) => {
    if (!rowIndexes || rowIndexes.length === 0) {
      return
    }
    let dialogTitle = t('control-items-list.confirm-point-deletion.multiple')
    const idsToDelete = tableData.items
      .filter((item, index) => rowIndexes.indexOf(index) !== -1)
      .map((item) => item.id)

    if (idsToDelete.length === 1) {
      const { title } = tableData.items[rowIndexes[0]]
      dialogTitle = t('control-items-list.confirm-point-deletion.single', { title })
    }
    showConfirmationDialog(
      dialogTitle,
      () => {
        dataProvider
          .deleteBulk('/control-items/bulk', idsToDelete)
          .then(
            () => {
              // Clean up reorganizeOps which reference this deleted item
              const filteredReorganizeOperations = reorganizeOperations.filter((op) => {
                const deletedToId = idsToDelete.indexOf(op.toId) !== -1
                const deletedFromId = idsToDelete.indexOf(op.fromId) !== -1
                return !deletedToId && !deletedFromId
              })
              setReorganizeOperations(filteredReorganizeOperations)
              setReorganizeItemFromId(null)

              // Sync row selection by removing deleted elements
              // eslint-disable-next-line max-len
              const filteredSelection = selection.rowsSelected.filter((index) => rowIndexes.indexOf(index) === -1)
              setSelection({ rowsSelected: filteredSelection })

              showNotification('success', t('control-items-list.confirm-point-deletion.success'))
              refreshData(true)
            },
          ).catch(
            () => showNotification('error', t('errors.api-error')),
          )
      },
    )
    // eslint-disable-next-line max-len
  }, [dataProvider, refreshData, reorganizeOperations, selection.rowsSelected, showConfirmationDialog, showNotification, t, tableData])

  const performArchiveStatusChange = useCallback((rowIndexes, archive) => {
    if (!rowIndexes || rowIndexes.length === 0) {
      return
    }
    let dialogTitle = archive
      ? t('control-items-list.confirm-point-archive.multiple')
      : t('control-items-list.confirm-point-unarchive.multiple')

    const idsToArchiveUnarchive = tableData.items
      .filter((item, index) => rowIndexes.indexOf(index) !== -1)
      .map((item) => item.id)

    if (idsToArchiveUnarchive.length === 1) {
      const { title } = tableData.items[rowIndexes[0]]
      dialogTitle = archive
        ? t('control-items-list.confirm-point-archive.single', { title })
        : t('control-items-list.confirm-point-unarchive.single', { title })
    }

    const data = {
      isArchived: archive,
    }
    showConfirmationDialog(
      dialogTitle,
      () => {
        dataProvider
          .updateBulk('control-items/bulk', { ids: idsToArchiveUnarchive, data })
          .then(
            () => {
              // Clean up reorganizeOps which reference this deleted item
              const filteredReorganizeOperations = reorganizeOperations.filter((op) => {
                const archivedUnarchivedToId = idsToArchiveUnarchive.indexOf(op.toId) !== -1
                const archivedUnarchivedFromId = idsToArchiveUnarchive.indexOf(op.fromId) !== -1
                return !archivedUnarchivedToId && !archivedUnarchivedFromId
              })
              setReorganizeOperations(filteredReorganizeOperations)
              setReorganizeItemFromId(null)

              // Sync row selection by removing deleted elements
              // eslint-disable-next-line max-len
              const filteredSelection = selection.rowsSelected.filter((index) => rowIndexes.indexOf(index) === -1)
              setSelection({ rowsSelected: filteredSelection })

              showNotification('success', archive ? t('control-items-list.confirm-point-archive.success') : t('control-items-list.confirm-point-unarchive.success'))
              refreshData(true)
            },
          ).catch(
            () => showNotification('error', t('errors.api-error')),
          )
      },
    )
    // eslint-disable-next-line max-len
  }, [dataProvider, refreshData, reorganizeOperations, selection.rowsSelected, showConfirmationDialog, showNotification, t, tableData])

  const onDelete = (rowIndex) => {
    performDelete([rowIndex])
  }

  const onReorganize = (rowIndex) => {
    const { id, itemOrder } = tableData.items[rowIndex]
    if (reorganizeItemFromId === null) {
      setReorganizeItemFromId({ id, itemOrder })
    } else if (reorganizeItemFromId.id === id) {
      setReorganizeItemFromId(null)
    } else {
      const reorganizeOperation = {
        fromId: reorganizeItemFromId.id,
        fromItemOrder: reorganizeItemFromId.itemOrder,
        toId: id,
        controlItemsListId,
      }
      dataProvider.put('/control-items/reorder', {
        data: {
          fromId: reorganizeOperation.fromId,
          toId: reorganizeOperation.toId,
          controlItemsListId: reorganizeOperation.controlItemsListId,
        },
      }).then(
        () => {
          // Store op for Undo
          setReorganizeOperations([...reorganizeOperations, reorganizeOperation])
          setReorganizeItemFromId(null)
          showNotification('success', t('common.item-reordered'))
          refreshData(true)
        },
      ).catch(
        () => showNotification('error', t('errors.api-error')),
      )
    }
  }

  const onUndoReorganizeOperation = () => {
    if (reorganizeOperations.length <= 0) {
      return
    }
    const operationIndex = reorganizeOperations.length - 1
    const operationToUndo = reorganizeOperations[operationIndex]
    dataProvider.put('/control-items/reorder/undo', {
      data: {
        id: operationToUndo.fromId,
        originalItemOrder: operationToUndo.fromItemOrder,
        controlItemsListId: operationToUndo.controlItemsListId,
      },
    }).then(
      () => {
        // Remove Undo operation
        const updatedOperations = [...reorganizeOperations]
        updatedOperations.splice(operationIndex, 1)
        setReorganizeOperations(updatedOperations)
        showNotification('success', t('common.item-reordered'))
        refreshData(true)
      },
    ).catch(
      () => showNotification('error', t('errors.api-error')),
    )
  }

  const performCreateItemBelowSelection = useCallback((rowIndex) => {
    const { id } = tableData.items[rowIndex]
    setOpenCreateControlItemDialog({
      dialogOpen: true,
      createBelowItemWithId: id,
    })
  }, [tableData])

  const onCreateItemBelowSelection = () => {
    if (selection && selection.rowsSelected && selection.rowsSelected.length === 1) {
      performCreateItemBelowSelection(selection.rowsSelected[0])
    }
  }

  const onDeleteSelection = () => {
    if (selection && selection.rowsSelected) {
      performDelete(selection.rowsSelected)
    }
  }

  const onArchiveSelection = () => {
    if (selection && selection.rowsSelected) {
      performArchiveStatusChange(selection.rowsSelected, true)
    }
  }

  const onUnarchiveSelection = () => {
    if (selection && selection.rowsSelected) {
      performArchiveStatusChange(selection.rowsSelected, false)
    }
  }

  const tableColumns = generateColumns({
    t,
    classes,
    reorganizeItemFromId,
    onDelete,
    onModify,
    onReorganize,
    onShowPhotos,
    tableData,
  })

  const createRightClickMenuItems = useCallback((rowIndex) => {
    if (!selection || !selection.rowsSelected) {
      return []
    }

    if (selection.rowsSelected.length === 1 || selection.rowsSelected.length === 0) {
      return [
        <MenuItem
          key={1}
          onClick={() => {
            performCreateItemBelowSelection(rowIndex)
          }}
        >
          <Grid container alignItems="center" spacing={2} className={classes.menuItemRow}>
            <AddOutlineIcon id="icon" />
            <Grid item>
              {t('control-items-list.create-point-below-line')}
            </Grid>
          </Grid>
        </MenuItem>,
        <MenuItem
          key={2}
          onClick={() => performDelete([rowIndex])}
        >
          <Grid container alignItems="center" spacing={2} className={classes.menuItemRow}>
            <TrashIcon id="icon" />
            <Grid item>
              {t('control-items-list.delete-point.single')}
            </Grid>
          </Grid>
        </MenuItem>,
      ]
    }

    return [
      <MenuItem
        key={2}
        onClick={() => performDelete(selection.rowsSelected)}
      >
        <Grid container alignItems="center" spacing={2} className={classes.menuItemRow}>
          <TrashIcon id="icon" />
          <Grid item>
            {t('control-items-list.delete-point.multiple')}
          </Grid>
        </Grid>
      </MenuItem>,
    ]
  }, [performCreateItemBelowSelection, performDelete, t, classes.menuItemRow, selection])

  if (loading || loadingData) {
    return <Loading />
  }
  const title = itemsList ? `${itemsList.vehicleTypes.map((el) => el.title).join(', ')} - ${itemsList.controlType.name}, ${dateFnsFormat(
    Date.parse(itemsList.createdAt),
    'dd/MM/yyyy H:mm:ss',
  )}` : t('control-items-list.title-placeholder')
  return (
    <>
      <CreateControlItemDialog
        open={openCreateControlItemDialog.dialogOpen}
        onCreated={() => {
          setOpenCreateControlItemDialog({
            dialogOpen: false,
            createBelowItemWithId: null,
          })
          refreshData(true)
        }}
        onClose={() => {
          setOpenCreateControlItemDialog({
            dialogOpen: false,
            createBelowItemWithId: null,
          })
        }}
        controlItemsListId={controlItemsListId}
        belowItemWithId={openCreateControlItemDialog.createBelowItemWithId}
      />
      <ModifyControlItemDialog
        open={openModifyControlItemDialog}
        originalControlItem={controlItemToModify}
        onModified={
          () => {
            setControlItemToModify(false)
            setOpenModifyControlItemDialog(false)
            refreshData(true)
          }
        }
        onClose={() => {
          setControlItemToModify(false)
          setOpenModifyControlItemDialog(false)
        }}
      />
      <PhotoGaleryDialog
        open={openPhotoDialog}
        onClose={() => setOpenPhotoDialog(false)}
        images={photoDialogUrls}
      />
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item md={10}>
          <Grid container direction="row" alignItems="center">
            <Grid item md={1}>
              <IconButton onClick={history.goBack}>
                <BackIcon />
              </IconButton>
            </Grid>
            <Grid item md={10}>
              <PageTitle title={t('control-items-list.title', { controlPoint: title })} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container md={2} justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenCreateControlItemDialog({
              dialogOpen: true,
              createBelowItemWithId: null,
            })}
          >
            {t('control-items-list.add-control-point')}
          </Button>
        </Grid>
      </Grid>
      <Grid container item className={classes.filtersContainer}>
        <Grid item>
          <Typography variant="h6" className={classes.filterContainerTitleText}>
            {t('common.filterZone')}
          </Typography>
        </Grid>
        <Grid item container alignItems="flex-end" spacing={1}>
          <Grid item xs={12} md={3}>
            <AutoCompleteInput
              onChange={setControlItemType}
              label={t('common.typology')}
              resource="control-items/type-autocomplete"
              value={controlItemType}
              canDelete
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <AutoCompleteInput
              onChange={setControlItemGroup}
              label={t('common.group')}
              resource="control-items/group-autocomplete"
              value={controlItemGroup}
              canDelete
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label={t('control-items-list.search')}
              fullWidth
              value={q}
              onChange={(event) => setQ(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControlLabel
              control={(
                <Checkbox
                  color="primary"
                  onChange={(event) => setIPdi(event.target.checked ? '1' : '0')}
                  checked={iPdi === '1'}
                />
              )}
              label={t('control-items-list.filter-only-isuzu-pdi')}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControlLabel
              control={(
                <Checkbox
                  color="primary"
                  onChange={(event) => setIsArchived(event.target.checked ? '1' : '0')}
                  checked={isArchived === '1'}
                />
              )}
              label={t('control-items-list.filter-is-archived')}
            />
          </Grid>
        </Grid>
        <Grid item container alignItems="center" justifyContent="space-between" spacing={1}>
          <Grid item xs={12} md={6} />
          <Grid item xs={12} md={6}>
            <Grid container spacing={2} justifyContent="flex-end" className={classes.actionsContainer}>
              <Grid item xs={12} md={4} xl={2}>
                <Button
                  color="primary"
                  variant="text"
                  onClick={clearFilters}
                  fullWidth
                >
                  {t('common.clean')}
                </Button>
              </Grid>
              <Grid item xs={12} md={4} xl={2}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  onClick={updateFilters}
                >
                  {t('common.search')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item>
          <Button
            variant="contained"
            fullWidth={fullWidth}
            className={classes.undoButton}
            disabled={(reorganizeOperations || []).length === 0}
            onClick={() => onUndoReorganizeOperation()}
          >
            <UndoIcon />
          </Button>
        </Grid>
        {(selection && selection.rowsSelected && selection.rowsSelected.length === 1)
          && (
            <Grid item>
              <Button
                variant="contained"
                fullWidth={fullWidth}
                className={[classes.allItemsButton].join(' ')}
                onClick={() => onCreateItemBelowSelection()}
              >
                <AddOutlineIcon id="icon" />
                <span id="text">{t('control-items-list.create-point-below-line')}</span>
              </Button>
            </Grid>
          )}
        {(selection && selection.rowsSelected && selection.rowsSelected.length > 0)
          && (
            <Grid item>
              <Button
                variant="contained"
                fullWidth={fullWidth}
                className={[classes.allItemsButton].join(' ')}
                onClick={() => onDeleteSelection()}
                disabled={selection && selection.rowsSelected && selection.rowsSelected.length <= 0}
              >
                <TrashIcon id="icon" />
                <span id="text">
                  {selection && selection.rowsSelected && selection.rowsSelected.length > 1
                    ? t('control-items-list.delete-point.multiple')
                    : t('control-items-list.delete-point.single')}
                </span>
              </Button>
            </Grid>
          )}
        {
        (selection && selection.rowsSelected && selection.rowsSelected.length > 0 && selection.rowsSelected.filter((el) => tableData.items[el].isArchived === false)).length > 0
          && (
            <Grid item>
              <Button
                variant="contained"
                fullWidth={fullWidth}
                className={[classes.allItemsButton].join(' ')}
                onClick={() => onArchiveSelection()}
                disabled={selection && selection.rowsSelected && selection.rowsSelected.filter((el) => tableData.items[el].isArchived === true).length > 0}
              >
                <span id="text">
                  {selection && selection.rowsSelected && selection.rowsSelected.length > 1
                    ? t('control-items-list.archive-point.multiple')
                    : t('control-items-list.archive-point.single')}
                </span>
              </Button>
            </Grid>
          )
}
        {(selection && selection.rowsSelected && selection.rowsSelected.length > 0 && selection.rowsSelected.filter((el) => tableData.items[el].isArchived === true)).length > 0
          && (
            <Grid item>
              <Button
                variant="contained"
                fullWidth={fullWidth}
                className={[classes.allItemsButton].join(' ')}
                onClick={() => onUnarchiveSelection()}
                disabled={selection && selection.rowsSelected && selection.rowsSelected.filter((el) => tableData.items[el].isArchived === false).length > 0}
              >
                <span id="text">
                  {selection && selection.rowsSelected && selection.rowsSelected.length > 1
                    ? t('control-items-list.unarchive-point.multiple')
                    : t('control-items-list.unarchive-point.single')}
                </span>
              </Button>
            </Grid>
          )}
      </Grid>
      {controlItemsListId
        && (
          <Grid item className={classes.tableContainer}>
            <Table
              id={resource}
              columns={tableColumns}
              data={tableData}
              page={tableState.page}
              sort={tableState.sort}
              selectableRows="multiple"
              pagination={false}
              onChangePage={(currentPage) => {
                setTableState({ ...tableState, page: currentPage })
              }}
              onColumnSortChange={(changedColumn, direction) => {
                let newSort = {
                  field: changedColumn,
                  direction: direction.toUpperCase(),
                }
                if (direction === 'none') {
                  newSort = null
                }
                setTableState({ ...tableState, sort: newSort })
              }}
              rowsSelected={selection.rowsSelected}
              onRowSelectionChange={(rowsSelected, allRows) => {
                setSelection({ rowsSelected: allRows.map((row) => row.dataIndex) })
              }}
              menuItems={(rowIndex) => createRightClickMenuItems(rowIndex, selection)}
              onRowDisabled
            />
          </Grid>
        )}
    </>
  )
}
