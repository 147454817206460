import React, { useState, useEffect } from 'react'

// components
import { useTranslation } from 'react-i18next'

import {
  Grid,
  Button,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import Loading from '../../components/Loading'
import PageTitle from '../../components/PageTitle'
import { useNotificationsProvider } from '../../context/NotificationsContext'
import { useDataApi } from '../../context/DataApiContext'
import { useConfirmationDialogProvider } from '../../context/ConfirmationDialogContext'
import {
  useUserState,
  useUserDispatch,
  refreshUser,
} from '../../context/UserContext'
import useStyles from './styles'

import Table from '../../components/Table'
import ModifyUserDialog from '../../components/Users/components/ModifyUserDialog'
import CreateUserDialog from '../../components/Users/components/CreateUserDialog'
import generateColumns from './columns'

export default function UsersList() {
  const { t } = useTranslation()

  const theme = useTheme()
  const fullWidth = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles()
  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()
  const { showConfirmationDialog } = useConfirmationDialogProvider()

  const [loading, setLoading] = useState(true)
  const [tableState, setTableState] = useState({
    page: 0, sort: null, filters: null,
  })
  const [tableData, setTableData] = useState(null)

  const [openModifyUserDialog, setOpenModifyUserDialog] = useState(false)
  const [openCreateUserDialog, setOpenCreateUserDialog] = useState(false)
  const [userToModify, setUserToModify] = useState(false)

  const userDispatch = useUserDispatch()
  const { user } = useUserState()

  const refreshData = (keepPage = false) => {
    if (keepPage) {
      setTableState({ ...tableState })
    } else {
      setTableState({ page: 0, sort: null, filters: null })
    }
  }

  const resource = 'users'

  useEffect(() => {
    dataProvider.getList(
      resource,
      {
        range: { page: tableState.page + 1 },
        filters: tableState.filters,
        sort: tableState.sort,
      },
    ).then((response) => {
      setTableData(response.data)
      setLoading(false)
    }).catch(() => showNotification('error', t('errors.api-error')))
    // eslint-disable-next-line
  }, [tableState])

  const onModify = (rowIndex) => {
    setUserToModify(tableData.items[rowIndex])
    setOpenModifyUserDialog(true)
  }

  const onDelete = (rowIndex) => {
    const { id, firstName } = tableData.items[rowIndex]
    showConfirmationDialog(
      t('users-list.confirm-user-deletion', { firstName }),
      () => {
        dataProvider.delete('users', { id })
          .then(() => {
            refreshData()
            showNotification('success', t('users-list.delete-success'))
          })
          .catch(() => showNotification('error', t('errors.api-error')))
      },
    )
  }

  const tableColumns = generateColumns({
    t,
    classes,
    meId: user.id,
    onDelete,
    onModify,
  })

  if (loading) {
    return <Loading />
  }
  return (
    <>
      <CreateUserDialog
        title={t('create-user-dialog.title')}
        open={openCreateUserDialog}
        onClose={() => setOpenCreateUserDialog(false)}
        onCreated={() => {
          setOpenCreateUserDialog(false)
          refreshData(true)
        }}
      />
      <ModifyUserDialog
        open={openModifyUserDialog}
        onClose={() => setOpenModifyUserDialog(false)}
        onModified={() => {
          setOpenModifyUserDialog(false)
          if (user.id === userToModify.id) {
            refreshUser(userDispatch)
          } else {
            refreshData(true)
          }
        }}
        title={t('users-list.modify-user')}
        userToModify={userToModify}
      />
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={12} sm={4}>
          <PageTitle title={t('users-list.title')} />
        </Grid>
        <Grid item container sm={8} justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            fullWidth={fullWidth}
            onClick={() => setOpenCreateUserDialog(true)}
          >
            {t('users-list.add-user')}
          </Button>
        </Grid>
      </Grid>
      <Grid item className={classes.tableContainer}>
        <Table
          id={resource}
          columns={tableColumns}
          data={tableData}
          page={tableState.page}
          sort={tableState.sort}
          onChangePage={(currentPage) => {
            setTableState({ ...tableState, page: currentPage })
          }}
          onColumnSortChange={(changedColumn, direction) => {
            const newSort = {
              field: changedColumn,
              direction: direction.toUpperCase(),
            }
            setTableState({ ...tableState, sort: newSort })
          }}
        />
      </Grid>
    </>
  )
}
