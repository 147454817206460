import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@material-ui/core'
import BaseDialog from '../../../BaseDialog'

import { useDataApi } from '../../../../context/DataApiContext'
import { useNotificationsProvider } from '../../../../context/NotificationsContext'
import AutoCompleteInput from '../../../AutoCompleteInput'
import validators from '../../../../utils/validators'

export default function ModifyVehicleDialog({
  open,
  onClose,
  onCreated,
  vehicleToModify,
}) {
  const { t } = useTranslation()

  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [vehicleType, setVehicleType] = useState(null)
  const [nSerie, setNSerie] = useState('')
  const [nIntern, setNIntern] = useState('')
  const [plate, setPlate] = useState('')
  const [vehicleState, setVehicleState] = useState('0')
  const [client, setClient] = useState('')

  const resetState = () => {
    // Clean form
    setLoading(false)
    setError('')
    setVehicleType(null)
    setPlate('')
    setPlate('')
    setNSerie('')
    setNIntern('')
    setVehicleState('0')
    setClient('')
  }

  const onCloseDialog = (event, reason) => {
    if ((reason === 'backdropClick') || (reason === 'escapeKeyDown')) {
      onClose()
    } else {
      resetState()
      onClose()
    }
  }

  const onConfirm = () => {
    setError(null)

    if (vehicleType === null) {
      setError('vehicleType_required')
      return
    }

    if (plate && plate.length > 0 && !validators.isValidPlate(plate)) {
      setError('plate_invalid')
      return
    }

    if (nIntern.length === 0) {
      setError('nIntern_required')
      return
    }

    if (vehicleState === null) {
      setError('vehicleState_required')
      return
    }

    if (client === null && vehicleState === '1') {
      setError('client_required')
      return
    }

    const data = {}
    if (vehicleToModify.vehicleTypeId !== vehicleType.id) {
      data.vehicleTypeId = vehicleType.id
    }
    if (vehicleToModify.plate !== plate) {
      data.plate = plate
    }
    if (vehicleState === '1' && vehicleToModify.clientId !== client.id) {
      data.clientId = client.id
    } else if (vehicleState === '0') {
      data.clientId = null
    }

    if (vehicleToModify.nSerie !== nSerie) {
      data.nSerie = nSerie
    }
    const sanitizedNIntern = (nIntern || '').trim().length === 0 ? undefined : (nIntern || '').trim()
    if (vehicleToModify.nIntern !== sanitizedNIntern) {
      data.nIntern = sanitizedNIntern
    }
    setLoading(true)
    dataProvider.update('vehicle', { data, id: vehicleToModify.id }).then(() => {
      dataProvider.cache.reset()
      setLoading(false)
      showNotification('success', t('modify-vehicle-dialog.success'))
      onCreated()
      resetState()
    }).catch((err) => {
      setLoading(false)
      if (err.response.data.validationErrors && err.response.data.validationErrors.length > 0) {
        showNotification('error', err.response.data.validationErrors[0].errorMessage)
        setError(`${err.response.data.validationErrors[0].field}_invalid`)
      } else {
        showNotification('error', t('errors.api-error'))
      }
    })
  }

  useEffect(() => {
    if (vehicleToModify) {
      setVehicleType(vehicleToModify.vehicleType)
      setPlate(vehicleToModify.plate)
      setNSerie(vehicleToModify.nSerie)
      setNIntern(vehicleToModify.nIntern)
      if (vehicleToModify.client) {
        setVehicleState('1')
        setClient({ id: vehicleToModify.client.id, title: vehicleToModify.client.name })
      } else {
        setVehicleState('0')
      }
    }
  }, [vehicleToModify])

  return (
    <BaseDialog
      open={open}
      onClose={onCloseDialog}
      onConfirm={onConfirm}
      title={t('modify-vehicle-dialog.title')}
      loading={loading}
    >
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <AutoCompleteInput
            error={error === 'vehicleType_required'}
            onChange={setVehicleType}
            label={t('common.vehicleType')}
            resource="vehicle-type/autocomplete"
            value={vehicleType}
            canDelete
          />
        </Grid>
        <Grid item>
          <TextField
            required
            error={error === 'nSerie_invalid'}
            helperText={error === 'nSerie_invalid'
              ? t('errors.not-valid')
              : t('modify-vehicle-dialog.nSerie-notice')}
            id="nSerie"
            label={t('common.nSerie')}
            fullWidth
            value={nSerie}
            onChange={(event) => setNSerie(event.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            required
            error={error === 'nIntern_required' || error === 'nIntern_invalid'}
            helperText={error === 'nIntern_invalid'
              ? t('errors.not-valid')
              : t('common.required')}
            id="nIntern"
            label={t('common.nIntern')}
            fullWidth
            value={nIntern}
            onChange={(event) => setNIntern(event.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            error={error === 'plate_invalid'}
            helperText={error === 'plate_invalid'
              ? t('errors.not-valid')
              : t('modify-vehicle-dialog.plate-notice')}
            id="plate"
            label={t('common.plate')}
            fullWidth
            value={plate || ''}
            onChange={(event) => setPlate(event.target.value)}
          />
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <InputLabel id="vehicleStateSelectLabel">{t('common.vehicleState')}</InputLabel>
            <Select
              error={error === 'vehicleState_required'}
              onChange={(event) => {
                setVehicleState(event.target.value)
                setClient(null)
              }}
              label={t('common.vehicleState')}
              value={vehicleState}
              required
              canDelete
              fullWidth
            >
              <MenuItem value="0">{t('common.vehicleNew')}</MenuItem>
              <MenuItem value="1">{t('common.vehicleSold')}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {
          vehicleState === '1'
          && (
            <Grid item>
              <AutoCompleteInput
                error={error === 'client_required'}
                onChange={setClient}
                label={t('common.client')}
                resource="clients/autocomplete"
                value={client}
                required
                canDelete
              />
            </Grid>
          )
        }
      </Grid>
    </BaseDialog>
  )
}
