import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Grid,
} from '@material-ui/core'

import BaseDialog from '../../BaseDialog'

import { useDataApi } from '../../../context/DataApiContext'
import { useNotificationsProvider } from '../../../context/NotificationsContext'
import AutoCompleteInput from '../../AutoCompleteInput'

export default function ModifyClientDialog({
  open,
  onClose,
  onModified,
  listToModify,
  duplicate,
}) {
  const { t } = useTranslation()

  const { dataProvider } = useDataApi()
  const { showNotification } = useNotificationsProvider()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [vehicleType, setVehicleType] = useState([])
  const [controlType, setControlType] = useState(null)

  const resetState = () => {
    // Clean form
    setLoading(false)
    setError('')

    if (listToModify) {
      setVehicleType(listToModify.vehicleTypes.map(el => {
        return {
          id: el.id,
          title: el.title,
        }
      }))
      setControlType({
        id: listToModify.controlType.id,
        title: listToModify.controlType.name,
      })
    } else {
      setVehicleType([])
      setControlType(null)
    }
  }

  const onCloseDialog = (event, reason) => {
    if ((reason === 'backdropClick') || (reason === 'escapeKeyDown')) {
      onClose()
    } else {
      resetState()
      onClose()
    }
  }

  const onConfirm = () => {
    if (!Array.isArray(vehicleType) || vehicleType.length === 0) {
      setError('vehicleType_required')
      return
    }

    if (!controlType) {
      setError('controlType_required')
      return
    }

    setLoading(true)

    let apiCallPromise
    if (duplicate) {
      const data = {
        vehicleTypeIds: vehicleType.map((el) => el.id),
        controlTypeId: controlType.id,
        duplicateItemsFromListId: listToModify.id,
      }
      apiCallPromise = dataProvider.create('control-items-lists', { data })
    } else {
      const data = {}
      data.vehicleTypeIds = vehicleType.map(el => el.id)
      if (listToModify.controlType.id !== controlType.id) {
        data.controlTypeId = controlType.id
      }
      apiCallPromise = dataProvider.update('control-items-lists', { data, id: listToModify.id })
    }
  
    apiCallPromise.then(() => {
      dataProvider.cache.reset()
      setLoading(false)
      showNotification('success', t('modify-control-items-list-dialog.success'))
      onModified()
      resetState()
    }).catch((err) => {
      setLoading(false)
      if (err.response.data.validationErrors && err.response.data.validationErrors.length > 0) {
        showNotification('error', err.response.data.validationErrors[0].errorMessage)
        setError(`${err.response.data.validationErrors[0].field}_invalid`)
      } else {
        showNotification('error', t('errors.api-error'))
      }
    })
  }

  useEffect(() => {
    if (listToModify) {
      setVehicleType(listToModify.vehicleTypes.map(el => {
        return {
          id: el.id,
          title: el.title,
        }
      }))
      setControlType({
        id: listToModify.controlType.id,
        title: listToModify.controlType.name,
      })
    }
  }, [listToModify])

  const title = duplicate ? t('modify-control-items-list-dialog.title-duplicate') : t('modify-control-items-list-dialog.title')
  return (
    <BaseDialog
      open={open}
      onClose={onCloseDialog}
      onConfirm={onConfirm}
      title={title}
      loading={loading}
    >
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <AutoCompleteInput
            error={error === 'vehicleType_required'}
            onChange={setVehicleType}
            label={t('create-control-items-list-dialog.model')}
            resource="vehicle-type/autocomplete"
            value={vehicleType}
            required
            multiple
            canDelete
          />
        </Grid>
        <Grid item>
          <AutoCompleteInput
            error={error === 'controlType_required'}
            onChange={setControlType}
            label={t('create-control-items-list-dialog.type')}
            resource="control-types/autocomplete"
            value={controlType}
            required
            canDelete
          />
        </Grid>
      </Grid>
    </BaseDialog>
  )
}
